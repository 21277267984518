import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom';
import { connect } from "react-redux";
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import BuktecSummary from './BuktecSummary';
import BusinessSummary from './BusinessSummary';
import CABoard from './CABoard';
import DashboardService from '../../Services/Dashboard/DashboardService';
import { displayDateFormate } from '../../Helpers/DateFormat';
import SessionManagement from '../../Utils/SessionManagement';
import { setAppilcationLoder } from "../../Redux/Actions/App";
import StorageData from "../../Helpers/StorageData";
import MasterService from '../../Services/MasterService';
import { Multiselect } from 'multiselect-react-dropdown';
import { toast } from 'react-toastify';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import ScrollPage from '../../Components/BoilerPlate/ScrollPage';

import {
  TheHeader,
  TheSidebar,
  TheHeaderNew,
  TheSidebarNew,
} from '../Containers/index';



// Intro js user guid
// import { render } from "react-dom";
// import { Steps } from "intro.js-react";
// End Intro js user guid 

class DashboardView extends Component {

  constructor(props) {
    super(props)

    let financial_years_arr = StorageData.getFinancialYear();
    let default_yr = StorageData.getDefaultFinancialYear();
    let selected_tab = StorageData.getDefaultDashboardSelectedTab()

    this.state = {
      financial_years_arr: financial_years_arr ? financial_years_arr : [],
      default_yr: default_yr,
      selected_tab: selected_tab ? selected_tab : 1,
      split_type: false,
      filter: 1,
      buktec_summary_data: {
        financial_institutes: [],
        reimbursements: [],
        snapshots: [],
        money_links: [],
        sequence: []
      },
      ca_board_data: {
        entities: { entity_invites: [], hashtag_entities: [] },
        user_wise_tasks: [],
        transactions_activities: [],
        licenses: {
          licenses_paid: [],
          licenses_status: []
        },
        sequence: []
      },
      business_summary_data: {
        sales_receivables: [],
        purchases_payables: [],
        sale_receivables: [],
        purchase_payables: [],
        expenses: [],
        category_wise_spends: [],
        merchant_wise_spends: [],
        tag_wise_spends: [],
        sequence: []
      },
      color1_background: 'rgba(255, 205, 86, 0.8)',
      color2_background: 'rgba(255, 99, 132, 0.8)',
      buktec_summary_last_updated_at: null,
      ca_board_last_updated_at: null,
      business_summary_last_updated_at: null,
      color3_background: 'rgba(75, 192, 192, 0.8)',
      color4_background: 'rgba(255, 128, 82, 0.8)',
      color5_background: 'rgba(0, 250 ,154, 0.8)',
      show_my_board_tab: false,
      year_type: default_yr.year_type ? 6 : 1,
      //this.state.financial_years_arr: [],
      year_type_index: 1,
      disable_split_type: false,
      start_date: "",
      end_date: "",

      // Intro js user guid
      // this.state = {
      // stepsEnabled: false,
      // initialStep: 0,  

      // steps: [	
      // 	{
      // 		element: ".tabs_dashboardin",
      // 		intro: "By default you will be doing Buktec Summary for summary of transaction.",
      // 		position: 'left',
      // 		// hideNext: true 
      // 	},
      // 	{
      // 		element: ".dropdown_dashboardin",
      // 		intro: "You can select a particular Financial Institute and specific time period or Select All."
      // 	},        
      // ],
      // };
      // End Intro js user guid
    }
  }

  componentDidMount = async () => {
    //alert(this.state.selected_tab)

    await this.getFinancialYear()
    let selected_tab = StorageData.getDefaultDashboardSelectedTab()

    if (selected_tab) {
      this.onChangeTab(parseInt(selected_tab))
    } else {
      this.onChangeTab(1)
    }

    let user_detail = SessionManagement.getUserDetail();
    if (user_detail.is_owner == 1 && user_detail.account_type == 2) {
      this.setState({ show_my_board_tab: true })
    } else {
      this.setState({ show_my_board_tab: false })
    }


  }

  getFinancialYear = () => {
    let year_type = this.state.default_yr.year_type
    MasterService.getFinancialYear().then((data) => {
      if (data.response_code == 200) {
        let fy = {
          id: 6,
          start_date: "",
          end_date: "",
          name: "Custom Date Range"
        };
        let financial_years_arr = [...data.result.date_filter, fy];
        //let financial_years_arr = financial_years;

        StorageData.setFinancialYear(financial_years_arr);

        if (year_type == 6) {
          year_type = 1
        }

        StorageData.setDefaultFinacialYear(year_type);
        let year_type_index = financial_years_arr ? financial_years_arr.findIndex(function (year) {
          return year.id == year_type
        }) : 1

        // let temp_index = financial_years_arr.findIndex(financial_year => financial_year.id == 6);
        // if (temp_index !== -1) {
        //   financial_years_arr.splice(temp_index, 1);
        // }
        // console.log(year_type_index)
        this.setState({ financial_years_arr, financial_years_arr, year_type_index: year_type_index }, () => this.onYearTypeChange(financial_years_arr[year_type_index].id));

      } else {
        toast.error(data.message);
      }
    });
  }

  getBuktecSummaryData = () => {
    this.props.dispatch(setAppilcationLoder(true));
    let data = {
      date_filter_id: this.state.filter,
      split_type: this.state.split_type ? 1 : 2
    }

    DashboardService.getBuktecSummaryData(data)
      .then((data) => {
        if (data.response_code == 200) {
          this.setState({ buktec_summary_data: data.result, buktec_summary_last_updated_at: data.result.last_updated_at })
        }
        this.props.dispatch(setAppilcationLoder(false));
      });
  }

  getCaBoardData = () => {
    this.props.dispatch(setAppilcationLoder(true));
    let data = {
      "date_filter_id": this.state.filter
    }

    DashboardService.getBoardData(data)
      .then((data) => {
        if (data.response_code == 200) {
          this.setState({ ca_board_data: data.result, ca_board_last_updated_at: data.result.last_updated_at })
        }
        this.props.dispatch(setAppilcationLoder(false));
      });
  }

  getBusinessSummaryData = () => {
    this.props.dispatch(setAppilcationLoder(true));
    let data = {
      date_filter_id: this.state.filter,
      split_type: this.state.split_type ? 1 : 2
    }

    DashboardService.getBusinessSummaryData(data)
      .then((data) => {
        if (data.response_code == 200) {
          console.log(data.result.sales_receivables)
          this.setState({ business_summary_data: data.result, business_summary_last_updated_at: data.result.last_updated_at })
        }
        this.props.dispatch(setAppilcationLoder(false));
      });
  }

  handleChange = (e) => {
    const target = e.target;
    const value = target.value;

    this.setState({ filter: value }, () => {
      this.onFilterChange();
    });
  }

  onFilterChange = () => {
    this.getBuktecSummaryData()
    this.getBusinessSummaryData()

    if (this.state.show_my_board_tab) {
      this.getCaBoardData()
    }
  }

  onChangeSplit = () => {
    this.setState({
      split_type: !this.state.split_type,
    }, () => {
      this.onFilterChange();
    });
  }

  onChangeTab = (value) => {
    this.setState({
      selected_tab: value
    });
    StorageData.setDefaultDashboardSelectedTab(value);
  }

  onYearTypeChange = (year_type) => {

    if (year_type == 1 || year_type == 2 || year_type == 5) {
      this.setState({ disable_split_type: true, split_type: true })
    } else if (year_type == 3) {
      //this.setState({disable_split_type: true, split_type: false})
      this.setState({ disable_split_type: false })
    } else {
      this.setState({ disable_split_type: false })
    }

    let start_date = this.state.financial_years_arr.find(x => x.id == year_type).start_date;
    let end_date = this.state.financial_years_arr.find(x => x.id == year_type).end_date;

    this.setState({
      year_type: year_type,
      filter: year_type,
      start_date: start_date ? new Date(start_date) : "",
      end_date: end_date ? new Date(end_date) : "",
    }, () => {
      this.getBuktecSummaryData()
      this.getBusinessSummaryData()
      if (this.state.show_my_board_tab) {
        this.getCaBoardData()
      }
      StorageData.setDefaultFinacialYear(year_type);
    });
  }

  selectYearHandler = (selectedList, selectedItem) => {
    this.onYearTypeChange(selectedItem.id)
  }

  // // Intro js user guid   
  // toggleSteps = () => {
  // 	this.setState(prevState => ({ stepsEnabled: !prevState.stepsEnabled }));
  // };
  // onExit = () => {
  // 	this.setState(() => ({ stepsEnabled: false }));
  // };
  // // End Intro js user guid 

  render() {

    let arr = [...this.state.financial_years_arr]
    let temp_index = this.state.financial_years_arr.findIndex(financial_year => financial_year.id == 6)
    if (temp_index !== -1) {
      arr.splice(temp_index, 1);
    }

    // // Intro js user guid 
    // const {
    // 	stepsEnabled,
    // 	steps,
    // 	initialStep,
    // 	hintsEnabled,
    // 	hints
    // } = this.state;
    // // End Intro js user guid

    return (
      <main className="content-wrapper-new">
        <div className="container-fluid container-padding-new">

          <ScrollPage />

          {/* Breadcrumb */}
          <div className="row my-1">
            <div className="col-12">
              <div className="main-sub-header-new mb-2">
                <div className="breadcrumb-header-new  py-1">
                  <div className="row">
                    <div className="col-4">
                      <h2 className="breadcrumb-title-new">Dashboard</h2>
                    </div>
                    <div className="col-8 text-right">
                      <div className="active-in-btn-sec ">
                        <ToggleButtonGroup
                          className="toogle-btn-ra"
                          type="radio"
                          name="options"
                          value={this.state.selected_tab}
                          onChange={this.onChangeTab}
                        //defaultValue={1} 
                        // onChange={(event, newTab) => {
                        //   this.onChangeTab(newTab);
                        // }}
                        >
                          <ToggleButton id={1} value={1}>Buktec Summary</ToggleButton>
                          <ToggleButton id={2} value={2}>Business Summary</ToggleButton>
                          {this.state.show_my_board_tab && <ToggleButton id={3} value={3}>My board</ToggleButton>}
                        </ToggleButtonGroup>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Breadcrumb */}


          <div className="form-content-sec-new mt-0">
            {/* Global search Bar */}
            <div className="row ">
              <div className="col-8">
                <div className="mainFilter-new my-1">
                  <div className="filter-bar-new">
                    {/* Select */}
                    <div className="global-ddown-new">
                      {this.state.financial_years_arr.length != 0 ? <Multiselect
                        onSelect={this.selectYearHandler}
                        options={arr}
                        singleSelect
                        placeholder="Select"
                        displayValue="name"
                        // selectedValues={[
                        //   this.state.financial_years_arr[this.state.year_type_index]
                        // ]}
                        selectedValues={[this.state.financial_years_arr.find((x) => x.id == this.state.year_type)]}
                      /> : null}
                    </div>
                    {/* End Select */}

                    {/* Weekly Monthly */}
                    {this.state.selected_tab != 3 &&
                      <div className="toggle-switch-btn toggle-big-switch-dash toggle-switch-center">
                        <span className="toggle-switch-center">
                          <span className="toggle-btn-sec-text">Weekly</span>
                          <label className="switch">
                            <input type="checkbox" onChange={this.onChangeSplit} checked={this.state.split_type} disabled={this.state.disable_split_type} />
                            <span className={"slider round " + (this.state.disable_split_type ? "disable-opacity" : "")}></span>
                          </label>
                          <span className="toggle-btn-sec-text">Monthly</span>
                        </span>
                      </div>
                    }
                    {/* End Weekly Monthly */}
                  </div>
                </div>
              </div>

              <div className="col-4 text-right">
                <div className="last-updated-dash">
                  <p className="lu-lable">Last updated</p>
                  {(this.state.buktec_summary_last_updated_at && this.state.selected_tab == 1) && <p className="lu-date">{this.state.buktec_summary_last_updated_at}</p>}
                  {(this.state.business_summary_last_updated_at && this.state.selected_tab == 2) && <p className="lu-date">{this.state.business_summary_last_updated_at}</p>}
                  {(this.state.ca_board_last_updated_at && this.state.selected_tab == 3) && <p className="lu-date">{this.state.ca_board_last_updated_at}</p>}
                </div>
              </div>
            </div>
            {/* End Global search Bar */}


            {this.state.selected_tab == 1 && <BuktecSummary
              buktec_summary_data={this.state.buktec_summary_data}
              color1_background={this.state.color1_background}
              color2_background={this.state.color2_background}
              color3_background={this.state.color3_background}
              color4_background={this.state.color4_background}
              color5_background={this.state.color5_background}
              split_type={this.state.split_type}
            />}

            {this.state.selected_tab == 2 && <BusinessSummary
              business_summary_data={this.state.business_summary_data}
              color1_background={this.state.color1_background}
              color2_background={this.state.color2_background}
              split_type={this.state.split_type}
            />}

            {this.state.selected_tab == 3 && <CABoard
              ca_board_data={this.state.ca_board_data}
              color1_background={this.state.color1_background}
              color2_background={this.state.color2_background}
              color3_background={this.state.color3_background}
              color4_background={this.state.color4_background}
              color5_background={this.state.color5_background}
              split_type={this.state.split_type}
            />}

            {/* Scroll Slider */}
            {/* <div className="row ">
              <div className="col-8">

              </div>
            </div> */}
            {/* End Scroll Slider */}
          </div>
        </div>
      </main>
    )
  }
}

function mapStateToProps(state) {
  const all_value = state.Snapshot || {};

  return {

  };
}

export default connect(mapStateToProps)(DashboardView);