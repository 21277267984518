import React from 'react'
import { Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { LEDGER_ENTRIES_CODE } from '../../Helpers/Constants';
import { allowNumbersOnly, allowNumbersAndDecimalsOnly } from '../../Utils/RegaxValidation';
import Select from 'react-select';
import CurrencyInput from 'react-currency-input-field';
import { decodeHtml, duplicateLedgerIndexFound } from '../../Helpers/HelperFunction';
import MoneyLinkService from '../../Services/MoneyLink/MoneyLinkService';
import { toast } from 'react-toastify';
import CostCategory from './CostCategory';
import BillWise from '../../Components/BoilerPlate/BillWise';
import { withRouter } from 'react-router-dom';
import { roundOffValue } from '../../Views/Snapshot/Roundoff';

// const SelectSearch = {
// 	control: styles =>
// 	({
// 		...styles,
// 		// color: '#2C275F!important',
// 		borderColor: '#707070',
// 		boxShadow: '0px !important',
// 		fontSize : '13px',
// 		// fontFamily: 's-sbold !important',

// 		'&:focus': {
// 			borderColor: '#77d7b1 !important',
// 			boxShadow: '1px #77d7b1 !important',
// 		},
// 		'&:hover': {
// 			borderColor: '#77d7b1 !important',
// 			boxShadow: '0px !important',
// 		},

// 	}),

// 	placeholder: styles => ({ ...styles, color: '#2C275F' }),
// }


class LedgerView extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			guid: this.props.guid,
			type: this.props.type,
			ledger_option: { label: decodeHtml(this.props.ledger_name), value: this.props.guid },
			amount: this.props.amount,
			ledgerErrorMessage: "",
			is_permission_granted: true, //this.props.features_permissions_list.includes(LEDGER_ENTRIES_CODE),
			voucher_nos: this.props.voucherNos,
			ledger_options: [],
			type_disabled: false,
			new: true,
			has_cost_centre: 0,
			cost_categories_list: [],
			cost_categories: this.props.cost_categories,
			cost_category: {},
			cost_center_list: [],
			showBillWise: false,
			has_billwise_allocation: false,
			allocation_tags: [],
			billwise_allocation: this.props.billwise_allocation
		}
		//console.log(this.props)
	}

	toggelBillWiseModal = () => {
		this.setState({ showBillWise: !this.state.showBillWise })
	}

	componentWillReceiveProps(nextProps) {
		// if (nextProps.is_valid_ledger !== this.props.is_valid_ledger) {
		// 	this.setState({new:false});
		// }
		if (nextProps.ledgerNameList !== this.props.ledgerNameList) {

			//this.setState({ guid: this.props.guid, ledger_option: { label: decodeHtml(this.props.ledger_name), value: this.props.guid }})
		}

		if (!_.isEqual(nextProps.ledgerNameList, this.props.ledgerNameList)) {
			//console.log('fff')
			//if (nextProps.ledgerNameList !== this.props.ledgerNameList) {
			if (nextProps.ledgerNameList.hasOwnProperty('ledger_list')) {
				let options = []
				nextProps.ledgerNameList.ledger_list.map((ledger, index) => {
					options.push({ label: decodeHtml(ledger.name), value: ledger.guid })
					if (this.state.guid == ledger.guid) {
						this.setState({ ledger_option: { label: decodeHtml(ledger.name), value: ledger.guid } })
					}
				})
				this.setState({ ledger_options: options })
			}
		}

		if (nextProps.type !== this.props.type) {
			//console.log("called type");
			this.setState({ type: parseInt(nextProps.type) })
		}

		if (this.props.guid != nextProps.guid) {
			this.setState({
				guid: nextProps.guid,
				ledger_option: { label: decodeHtml(nextProps.ledger_name), value: nextProps.guid },
				type: parseInt(nextProps.type),
				amount: nextProps.amount,
				cost_categories: nextProps.cost_categories,
				billwise_allocation: nextProps.billwise_allocation
			})
		}
	}

	componentDidUpdate = (prevProps) => {
		if (this.props.submit_click != prevProps.submit_click) {
			this.setState({ new: false })
		}

		if (this.props.guid != prevProps.guid) {
			this.setAllocationData();
		}

		if (prevProps.temp_linked_evidences.length !== this.props.temp_linked_evidences.length) {
			//console.log(this.props.temp_linked_evidences);
			this.setAllocationData()
		}
	}

	componentDidMount = () => {
		//console.log('ss')
		let options = []
		setTimeout(() => {
			let has_billwise_allocation = false;

			if (this.props.ledgerNameList.hasOwnProperty('ledger_list')) {
				this.props.ledgerNameList.ledger_list.map((ledger, index) => {
					options.push({ label: decodeHtml(ledger.name), value: ledger.guid })
					if (this.state.guid == ledger.guid) {

						//check if billwise allocation flag is set for already selected ledger
						this.props.ledgerNameList.ledger_list.forEach(opt => {
							if (opt.guid == this.state.guid && opt.has_billwise == 1) {
								has_billwise_allocation = true
							}
						});

						this.setState({ ledger_option: { label: decodeHtml(ledger.name), value: ledger.guid } })
						let temp_ledger = {
							amount: this.state.amount,
							ledger_id: ledger.id,
							guid: this.state.guid,
							name: ledger.name,
							transaction_voucher_ledger_id: parseInt(this.props.transaction_voucher_ledger_id),
							voucher_ledger_id: parseInt(this.props.transaction_voucher_ledger_id),
							type: parseInt(this.state.type),
							cost_categories: this.state.cost_categories,
							billwise_allocation: this.props.billwise_allocation
						}
						this.props.updateLedger(temp_ledger, this.props.ledger_index, "first_time")

						//console.log(this.state.ledger_option)
					}

				})
				this.setState({ ledger_options: options, has_billwise_allocation })
			}

			if (has_billwise_allocation) {
				//console.log('present');
				this.setAllocationData();
			}
		}, 0);

		//change requrement - type should not be disable, so true condition added
		if (this.props.ledger_index == 0 || this.props.ledger_index == 1) {
			this.setState({ type_disabled: true })
		} else {
			this.setState({ type_disabled: false })
		}
	}

	setAllocationData = () => {
		//check prefill condition
		let temp_voucher_list = _.cloneDeep(this.props.temp_voucher_list);
		//console.log(this.props.temp_voucher_list);
		let billwise_allocation_done = false;
		let billwise_allocation_prefilling_done = false;
		let multiple_billwise_ledgers = [];

		temp_voucher_list = temp_voucher_list.map((voucher, index) => {
			voucher.ledgers = voucher.ledgers.map((ledger, ledger_index) => {
				//console.log(ledger.billwise_allocation)
				if (ledger.billwise_allocation.length > 0) {
					if (ledger.billwise_allocation[0].tag != "" && ledger.billwise_allocation[0].transaction_voucher_ledger_billwise_allocation_id != 0) {
						billwise_allocation_done = true;
					}

					if (ledger.billwise_allocation[0].tag != "") {
						multiple_billwise_ledgers[ledger_index] = ledger.billwise_allocation.length;
					}
				}
			})
		})
		//console.log(this.props.guid)
		//check if billwise allocation flag is set for already selected ledger
		let has_billwise_allocation = false;
		this.props.ledgerNameList.ledger_list.forEach(opt => {
			if (this.props.guid == opt.guid && opt.has_billwise == 1) {
				//console.log(opt);
				has_billwise_allocation = true
			}
		});

		//console.log(billwise_allocation_done,has_billwise_allocation ,this.props.account_status, multiple_billwise_ledgers.length)

		if (!billwise_allocation_done && has_billwise_allocation && this.props.account_status == 3 && this.props.voucher_type != 1) {
			// let allocation_tags = this.props.billwise_allocation;
			let allocation_tags = [];
			//console.log(this.props.temp_linked_evidences)
			if (this.props.temp_linked_evidences.length > 0) { //alert('ddd')
				this.props.temp_linked_evidences.forEach((evidence, index) => {
					if (evidence.transaction_linked_amount > 0 && !!evidence.invoice_no) {

						let ref_type = "";
						let tag = "";
						let ref_name = evidence.invoice_no;

						if (this.props.voucher_type == 4 || this.props.voucher_type == 5 || this.props.voucher_type == 6) {
							ref_type = 1;
							tag = "<b>" + evidence.invoice_no + "</b> (₹" + (evidence.transaction_linked_amount) + ")";
						} else {
							ref_type = 2;
							tag = evidence.invoice_no + " (₹" + (evidence.transaction_linked_amount) + ")";
						}
						//console.log(evidence.mod_id);
						allocation_tags.push({
							"amount": evidence.transaction_linked_amount,
							"invoice_no": ref_name,
							"tag_type": 1,
							"transaction_voucher_ledger_billwise_allocation_id": 0,
							"type": evidence.type || 1,
							//"type": 1,
							"type_of_ref": ref_type,
							"merchant_id": evidence.merchant_id,
							"tag": tag,
							"source": 2,
							"source_id": evidence.mod_id || evidence.id,
						})
						//console.log(ref_name)
					}
				})


			}

			if (allocation_tags.length > 0) {
				toast.warning('Billwise allocation data is prefilled');
			}

			//console.log(allocation_tags)
			this.setState({ billwise_allocation: allocation_tags }, () => {
				let temp_ledger = this.props.ledgers[this.props.ledger_index];
				temp_ledger.billwise_allocation = allocation_tags;
				//console.log(temp_ledger.billwise_allocation)
				this.props.updateLedger(temp_ledger, this.props.ledger_index, allocation_tags.length == 0 ? 'first_time' : null)
			})
		} else {
			//console.log('no prefill')
			//this.setState({billwise_allocation: this.props.billwise_allocation})
		}
	}

	getCostcenterCategory = () => {
		MoneyLinkService.getCostcenterCategory().then(
			(data) => {
				if (data.response_code == 200) {
					let cost_categories_list = data.result.cost_categories.map((category) => { return { label: decodeHtml(category.name), value: category.guid, id: category.id } })
					this.setState({ cost_categories_list: cost_categories_list })
				} else {
					toast.error(data.message);
				}
			});
	}

	// ------- Made by Ram ------- 
	amountChange = (value, name) => {
		this.setState({ [name]: value }, () => {
			let amount = this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,this.state.amount) : this.state.amount;
			console.log("ledger amount",amount)
			if (this.state.guid != undefined) {
				let ledger = this.props.ledgerNameList.ledger_list.find(x => x.guid == this.state.guid)
				let l_name = this.props.ledgerNameList.ledger_list.find(x => x.guid == this.state.guid)
				let temp_ledger = {
					amount: amount,
					ledger_id: ledger ? parseInt(ledger.id) : 0,
					guid: this.state.guid,
					name: l_name ? l_name.name : "",
					transaction_voucher_ledger_id: parseInt(this.props.transaction_voucher_ledger_id),
					voucher_ledger_id: parseInt(this.props.transaction_voucher_ledger_id),
					type: parseInt(this.state.type),
					cost_categories: this.state.cost_categories,
					billwise_allocation: this.state.billwise_allocation

				}
				this.props.updateLedger(temp_ledger, this.props.ledger_index)
			}
		})
	}

	// ----------------------------------------- //

	handleChange = (e, type = null) => {
		let name;
		let value;
		let ledger_id;
		let l_name;
		let has_cost_centre = 0;

		if (this.state.is_permission_granted && !this.props.read_only) {
			if (type == 'ledger') {
				name = 'guid';
				value = e.value;
				let ledger = this.props.ledgerNameList.ledger_list.find(x => x.guid == value);

				ledger_id = ledger.id;
				l_name = ledger.name;
				let has_billwise_allocation = ledger.has_billwise == 1 ? true : false;

				this.setState({
					ledger_option: { label: <span dangerouslySetInnerHTML={{ __html: l_name }} />, value: value },
					has_cost_centre: ledger.has_cost_centre,
					cost_categories: [],
					has_billwise_allocation
				})
				has_cost_centre = ledger.has_cost_centre;
				//to hide cost center on production line 199,202 are commented. later we remove required validation
			} else {
				name = e.target.name
				value = e.target.value

				if (this.state.guid != "") {
					ledger_id = this.props.ledgerNameList.ledger_list.find(x => x.guid == this.state.guid).id;
					l_name = this.props.ledgerNameList.ledger_list.find(x => x.guid == this.state.guid).name;
				}
			}

			if (name === "amount" && allowNumbersAndDecimalsOnly(value) === false) {
				return
			}

			if (name === "type" && this.state.type_disabled) {
				return
			}
			this.setState({ [name]: value }, () => {

				//START- show remaining value in cr/dr ledger=========================
				let amt = 0
				if (type != 'ledger') {
					amt = this.props.remainingLedgerAmount(this.props.ledgers, this.state.type)
					this.setState({ amount: amt });
				} else if (type == 'ledger') {
					amt = this.props.ledgers[this.props.ledger_index].amount
				}
				//END- show remaining value in cr/dr ledger=============================
				//alert('gfgfg')
				let temp_ledger = {
					// amount: this.state.amount,
					//START- show remaining value in cr/dr
					amount: amt,
					//END- show remaining value in cr/dr
					ledger_id: parseInt(ledger_id) || '',
					guid: this.state.guid,
					name: l_name,
					transaction_voucher_ledger_id: parseInt(this.props.transaction_voucher_ledger_id),
					voucher_ledger_id: parseInt(this.props.transaction_voucher_ledger_id),
					type: parseInt(this.state.type),
					cost_categories: this.state.cost_categories,
					billwise_allocation: this.state.billwise_allocation
				}
				//console.log(temp_ledger)
				this.props.updateLedger(temp_ledger, this.props.ledger_index);
				if (!!has_cost_centre) {
					this.onAddCategory();
				}
			})
		}
	}

	ledgerErrorMessage = () => {
		if (this.state.guid === 0 || this.state.guid === '' || this.state.guid == null) {
			return "Select Ledger"
		}
		else return null
	}

	ledgerAmtErrorMessage = () => {
		if (this.state.amount == 0 || this.state.amount == '' || this.state.amount == null) {
			return "Enter Amount"
		} else return null
	}

	onAddCategory = () => {
		let cost_categories = this.state.cost_categories;
		let cost_category = {
			"category_guid": "",
			"category_id": 0,
			"category_name": "",
			"transaction_voucher_ledger_cost_category_id": 0,
			"cost_centers": [{
				"cost_center_guid": "",
				"cost_center_id": 0,
				"cost_center_name": "",
				"cost_center_amount": this.state.amount,
				"transaction_voucher_ledger_cost_category_cost_centre_id": 0
			}]
		}
		cost_categories.push(cost_category)
		this.setState({ cost_categories: [] }, () => {
			this.setState({ cost_categories: cost_categories });
			let temp_ledger = this.props.ledgers[this.props.ledger_index];
			temp_ledger.cost_categories = cost_categories;
			this.props.updateLedger(temp_ledger, this.props.ledger_index)
		})
	}

	getCostCenters = (category_guid) => {
		MoneyLinkService.getCostCenters(category_guid).then(
			(data) => {
				if (data.response_code == 200) {
					let cost_center_list = data.result.cost_centres.map((cost_centre) => { return { label: decodeHtml(cost_centre.name), value: cost_centre.guid } })
					this.setState({ cost_center_list })
				} else {
					toast.error(data.message);
				}
			});
	}

	removeCostCategory = (index) => {
		//console.log(index)
		let cost_categories = this.state.cost_categories;
		cost_categories.splice(index, 1);
		this.props.isFormDirty();
		this.setState({ cost_categories: cost_categories }, () => {
			if (!cost_categories.length)
				this.onAddCategory();
		})
		//console.log(cost_categories)
	}

	saveAllocationData = (data) => {
		let temp_ledger = this.props.ledgers[this.props.ledger_index];
		temp_ledger.billwise_allocation = data;
		this.props.updateLedger(temp_ledger, this.props.ledger_index)
		this.setState({ billwise_allocation: data })
	}

	getTagColor = (type) => {
		//console.log(type)
		switch (type) {
			case 1:
				return "green-tags"
			case 3:
				return "sky-blue-tags"
			default:
				return ""
		}
	}

	render() {
		console.log("ledger round off",this.props.amount_round_off);
		const read_only = this.props.read_only;
		//console.log(this.state.billwise_allocation)
		//console.log(this.props.temp_linked_evidences)
		//console.log(this.props.billwise_allocation)
		//console.log(this.props.guid)
		return (
			<>
				<tr key={"ledger_tr_" + this.props.ledger_index}>
					{/* <td className="align-top">  */}
					<td style={{ width: "65px" }}>
						<Form>
							<Form.Control as="select" name="type" value={this.state.type} readOnly={!this.state.is_permission_granted || read_only || this.state.type_disabled}
								onChange={this.handleChange}>
								{/* <option value=''>Select</option> */}
								<option value={1}>CR</option>
								<option value={2}>DR</option>
							</Form.Control>
						</Form>
					</td>
					<td>
						<Form>
							{/* SOC
							Date - 9/9/21
							Developer - Aallesha Fulzele
							Add new restrictions according to voucher type */}
							<Select
								// className="form-control"
								// className="Select-Search"
								className="Select-Search"
								classNamePrefix={"Select SelectLedger " + (!!this.props.is_leder_recommended && this.props.guid != null ? "input-bg-green " : "")}
								value={this.state.ledger_option}
								isDisabled={!this.state.is_permission_granted || read_only}
								isClearable={false}
								isRtl={false}
								isSearchable={true}
								name="guid"
								options={this.state.ledger_options}
								onChange={(e) => this.handleChange(e, 'ledger')}

								// styles={SelectSearch}
								// theme={(theme) => ({
								// 	...theme,
								// 	borderRadius: 0,
								// 	fontSize : '13px',
								// 	colors: {
								// 		...theme.colors,
								// 		primary25: '#77D7B1',
								// 		primary: '#ffffff',
								// 	},
								// })}
							/>
							{/* EOC */}
							<small className="error_right">{(this.props.is_valid_ledger === false && !this.state.new) && this.ledgerErrorMessage()}</small>
							<small className="error_right">{duplicateLedgerIndexFound(this.props.ledgers,this.props.ledger_index)   ? "Duplicate ledger found":""}</small>
						</Form>
					</td>

					<td>
						<CurrencyInput
							type="text"
							className={"form-control text-right " + (!!this.props.is_leder_recommended ? "input-bg-green " : "")}
							name="amount"
							autoComplete="off"
							placeholder={this.state.type == 2 ? '₹ 0' : ""}
							prefix="&#8377; "
							value={this.state.type == 2 ? this.state.amount : ""}
							allowNegativeValue={false}
							decimalsLimit={2}
							disabled={(this.state.type == 2 && this.state.is_permission_granted) && !read_only ? false : true}
							onValueChange={(value, name) => this.amountChange(value, name)}
							onBlur={() => {
								this.setState({amount : this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,this.state.amount) : this.state.amount})
							}}
						/>
						<small className="error_right">{(this.props.is_valid_ledger === false && this.state.type == 2 && !this.state.new) && this.ledgerAmtErrorMessage()}</small>
					</td>

					<td>
						<CurrencyInput
							type="text"
							className={"form-control text-right " + (!!this.props.is_leder_recommended ? "input-bg-green " : "")}
							name="amount"
							autoComplete="off"
							decimalsLimit={2}
							prefix="&#8377; "
							placeholder={this.state.type == 1 ? "₹ 0" : ""}
							value={this.state.type == 1 ? this.state.amount : ""}
							allowNegativeValue={false}
							disabled={(this.state.type == 1 && this.state.is_permission_granted) && !read_only ? false : true}
							onValueChange={(value, name) => this.amountChange(value, name)}
							onBlur={() => {
								this.setState({amount : this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,this.state.amount) : this.state.amount})
							}}
						/>
						<small className="error_right">{(this.props.is_valid_ledger === false && this.state.type == 1 && !this.state.new) && this.ledgerAmtErrorMessage()}</small>
					</td>

					{(this.state.is_permission_granted && !read_only && this.props.ledger_index > 1) &&
						<td className="text-right croslt">
							<span class="icon-Cross-with-grey-circle size-xx cpointer" onClick={() => this.props.removeLedger(this.props.ledger_index)}><span class="path1"></span><span class="path2 cpointer"></span><span class="path3"></span></span>
						</td>
					}
				</tr>


				{(this.state.has_billwise_allocation && this.props.voucher_type != 1 && !this.props.location.pathname.includes('/snapshot/evidence/create')) &&
					<tr>
						<td></td>
						<td>
							<div class="add-tag-ledger-new">
								{this.state.billwise_allocation.length > 0 && this.state.billwise_allocation.map((tag) => {
									if (!!tag.tag) {
										if (tag.tag != "") {
											return <div
												className={`tags ${this.getTagColor(tag.tag_type)}`}
												dangerouslySetInnerHTML={{ __html: tag.tag }}
											/>
										}
									}
								})}

								<button type="button" class="btn btn-success apply_btn_new btn-small" onClick={() => { this.toggelBillWiseModal() }}>
									<span><span class="icon-edit white-icon"></span> Edit Allocation</span>
								</button>

								{(this.state.billwise_allocation.length == 0 && this.props.account_status == 3) &&
									<small className="error_right d-block">
										Optional: Billwise allocation(s) not provided.
									</small>
								}
								{/* {this.state.billwise_allocation.length} */}
							</div>
						</td>
						<td></td>
					</tr>
				}


				{(this.state.has_billwise_allocation && this.props.voucher_type == 1) &&
					<tr>
						<td></td>
						<td colSpan={3}>
							{/* <p className="ft-sz-16 s-reg red-color mb-0">Billwise allocation is not applicable.</p> */}
							<small className="error_left d-block mb-0">
								Billwise allocation is not applicable.
							</small>
						</td>
					</tr>
				}







				{this.state.has_billwise_allocation && this.state.showBillWise && <BillWise
					showBillWise={this.state.showBillWise}
					toggelBillWiseModal={() => this.toggelBillWiseModal()}
					ledger_name={this.state.ledger_option.label}
					ledger_amount={this.state.amount}
					voucher_type={this.props.voucher_type}
					billwise_allocation={this.state.billwise_allocation}
					guid={this.state.guid}
					saveAllocationData={(allocation_data) => {
						this.saveAllocationData(allocation_data);
						this.toggelBillWiseModal()
					}}
					temp_linked_evidences={this.props.temp_linked_evidences}
					transaction_id={this.props.transaction_id}
				/>}

				{!!this.state.has_cost_centre && !this.props.cost_categories_list.length &&
					<tr><td></td>
						<td colSpan={3} className="ft-sz-16 s-reg red-color mb-0">Please import cost categories. You have enabled cost center in tally for this ledger but not imported in buktec.</td></tr>
				}
				{/* cost center */}

				{
					!!this.props.cost_categories_list.length &&
					this.state.cost_categories.map((category, index1) => {
						return <CostCategory
							key={"category_" + index1 + category.category_guid}
							ledger_index={this.props.ledger_index}
							cost_category_index={index1}
							cost_categories={this.state.cost_categories}
							cost_centers={category.cost_centers}
							category_amount={category.category_amount}
							category_guid={category.category_guid}
							category_name={category.category_name}
							cost_categories_list={this.state.cost_categories_list}
							removeCostCategory={this.removeCostCategory}
							ledger_amount={this.state.amount}
							is_leder_recommended={!!this.props.is_leder_recommended}
							is_valid_ledger={this.props.is_valid_ledger}
							read_only={this.props.read_only}
							transactionAmount={this.props.transactionAmount}
							voucherNos={this.props.voucherNos}
							voucher_type={this.props.voucher_type}
							submit_click={this.props.submit_click}
							isFormDirty={this.props.isFormDirty}
						/>
					})
				}


				{(this.state.is_permission_granted && !read_only) && (!!this.state.cost_categories.length) && (!!this.props.cost_categories_list.length) &&
					<tr>
						<td colSpan={4} className="text-right addcat">
							<button className="add-cat-btn" onClick={() => this.onAddCategory()} >Add Category</button>

							{/* <button className="item-increment bg-transparent p-0 add-cat-btn mt-0" onClick={() => this.onAddCategory()} >Add Category</button> */}

							{/* {(this.state.is_permission_granted && !read_only) && (!!this.state.cost_categories.length) &&
							<button className="item-increment border-0 bg-transparent removebrd mt-0 p-0" onClick={() => this.onAddCategory()} >
								<span className="icon-plus"></span>
							</button>
						} */}
						</td>
						{/* <td colSpan={3}></td> */}
					</tr>
				}
			</>
		)
	}
}

function mapStateToProps(state) {
	const session_value = state.Session || {};
	const features_permissions_list = session_value.features_permissions_list || [];

	const money_link_values = state.MoneyLink || {};
	const cost_categories_list = money_link_values.cost_category_list || [];
	//const temp_linked_evidences = money_link_values.temp_linked_evidences;
	const temp_voucher_list = money_link_values.temp_voucher_list;

	return {
		features_permissions_list, cost_categories_list, temp_voucher_list
	};
}

export default connect(mapStateToProps)(withRouter(LedgerView));