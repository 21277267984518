import React from 'react'
import { Form, Table } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { LEDGER_ENTRIES_CODE } from '../../Helpers/Constants';
import { displayDateFormate, DatePickerFormate, displayDateTimeFormat, changeDateFormate, changeDateTimeFormat } from '../../Helpers/DateFormat';
import LedgerView from './LedgerView';
import ReactDatePicker from "react-datepicker";
import CurrencyInput from 'react-currency-input-field';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { confirm } from "../BoilerPlate/Confirm";
import ConfirmPopup from '../../Components/BoilerPlate/ConfirmPopup';
import { setTempVoucherList } from '../../Redux/Actions/MoneyLink';
import { setTempVoucherList as setSnapshotTempVoucherList } from '../../Redux/Actions/Snapshot';
import { rounding } from '../../Helpers/Currency';
import { areGuidsUnique } from '../../Helpers/HelperFunction';
class VoucherCardView extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            is_accounting_narration: this.props.accounting_narration ? true : false,
            accounting_narration: this.props.accounting_narration,
            total_debit_amount: parseFloat(this.props.total_debit_amount),
            total_credit_amount: parseFloat(this.props.total_credit_amount),
            voucherErrorMessage: "",
            is_permission_granted: true,//this.props.features_permissions_list.includes(LEDGER_ENTRIES_CODE),
            vochers_type: { 1: 'Contra', 2: 'Payment', 3: 'Receipt', 4: 'Journal', 5: 'Sales', 6: 'Purchase' },
            voucher_creation_date: new Date(DatePickerFormate(this.props.voucher_creation_date)),
            index_no: this.props.index,
            old_voucher_data: [],
            old_money_voucher_data: [],
            oldData: "",
            // is_modified:false,
            // ledgerModified:false,
            voucher_type_guid : this.props.voucher_type_guid
        }
        this[`wrapperRef${this.props.voucher_id}`] = React.createRef();
    }

    componentDidMount = () => {
        if (this.props.location.pathname.includes('/snapshot/evidence/create')) {

            document.addEventListener("mousedown", this.handleClickOutside);
            this.setState({ old_voucher_data: _.cloneDeep(this.props.voucher) })

        } else if (window.location.pathname.includes('money-link/')) {

            document.addEventListener("mousedown", this.handleClickOutsideMoneyLedger);
            this.setState({ old_money_voucher_data: _.cloneDeep(this.props.voucher) })
        }
    }

    componentWillUnmount() {
        if (window.location.pathname.includes('snapshot/evidence/create/')) {
            document.removeEventListener("mousedown", this.handleClickOutside);
        }
        if (window.location.pathname.includes('money-link/')) {
            document.removeEventListener("mousedown", this.handleClickOutsideMoneyLedger);
        }
    }

    handleClickOutside = async (event) => {
        if (this[`wrapperRef${this.props.voucher_id}`].current && !this[`wrapperRef${this.props.voucher_id}`].current.contains(event.target) && this.props.temp_snap_voucher_list[this.state.index_no].sync_status != 0 && this.props.temp_snap_voucher_list[this.state.index_no].sync_status != null) {

            let confirmMsg;

            let clonedVoucher = _.cloneDeep(this.props.voucher)
            let ledgers = this.props.voucher.ledgers.map((value, key) => {
                return _.omit(value, ['ledger_id', 'billwise_allocation'])
            })
            clonedVoucher.ledgers = ledgers

            let clonedOldVoucher = _.cloneDeep(this.state.old_voucher_data)
            let oldLedgers = this.state.old_voucher_data.ledgers.map((value, key) => {
                return _.omit(value, ['ledger_id', 'billwise_allocation'])
            })
            clonedOldVoucher.ledgers = oldLedgers
            clonedOldVoucher.total_credit_amount =  clonedOldVoucher.total_credit_amount.toFixed(2)
            clonedOldVoucher.total_debit_amount =  clonedOldVoucher.total_debit_amount.toFixed(2)

            console.log(clonedVoucher, clonedOldVoucher)

            if (!_.isEqual(clonedVoucher, clonedOldVoucher)) {

                this.setState({ old_voucher_data: _.cloneDeep(this.props.voucher) }, async () => {
                    confirmMsg = await confirm({ confirmation: 'Do you want to change voucher status to Not Synced?' });

                    if (confirmMsg) {
                        let temp_snap_voucher_list = this.props.temp_snap_voucher_list;
                        let index = temp_snap_voucher_list.findIndex(voucher => voucher.voucher_id == this.props.voucher_id)

                        temp_snap_voucher_list[index].sync_status = 0;
                        temp_snap_voucher_list[index].sync_datetime = (new Date());
                        this.props.dispatch(setSnapshotTempVoucherList(temp_snap_voucher_list));
                        this.props.isFormDirty();
                        this.setState({ is_modified: false })
                    }
                })
            }
        }
    }


    handleClickOutsideMoneyLedger = async (event) => {
        if (this[`wrapperRef${this.props.voucher_id}`].current && !this[`wrapperRef${this.props.voucher_id}`].current.contains(event.target) && this.props.temp_moneylink_voucher_list[this.state.index_no].sync_status != 0 && this.props.temp_moneylink_voucher_list[this.state.index_no].sync_status != null) {

            let clonedVoucher = _.cloneDeep(this.props.voucher)
            let ledgers = this.props.voucher.ledgers.map((value, key) => {
                return _.omit(value, ['voucher_ledger_id', 'ledger_id'])
            })
            clonedVoucher.ledgers = ledgers

            //if we searched evidence ->edit that evidence -> and comeback to current page it was showing below alert - to prevent this we have added below code
            let clonedOldVoucher = _.cloneDeep(this.state.old_money_voucher_data)
            let oldLedgers = this.state.old_money_voucher_data.ledgers.map((value, key) => {
                return _.omit(value, ['voucher_ledger_id', 'ledger_id'])
            })
            clonedOldVoucher.ledgers = oldLedgers
            //console.log(clonedVoucher, clonedOldVoucher)
            let confirmMsg;
            // if(!_.isEqual(clonedVoucher, this.state.old_money_voucher_data)){
            if (!_.isEqual(clonedVoucher, clonedOldVoucher)) {
                this.setState({ old_money_voucher_data: _.cloneDeep(clonedVoucher) }, async () => {
                    confirmMsg = await confirm({ confirmation: 'Do you want to change voucher status to Not Synced?' });
                    if (confirmMsg) {
                        let temp_moneylink_voucher_list = this.props.temp_moneylink_voucher_list;
                        let index = temp_moneylink_voucher_list.findIndex(voucher => voucher.voucher_id == this.props.voucher_id)
                        //set sync_status to not sync when ledger is modified even if previous sync_status was ( sent to sync/Synced/synced error) 
                        temp_moneylink_voucher_list[index].sync_status = 0
                        // temp_moneylink_voucher_list[index].sync_datetime = changeDateFormate(new Date())
                        temp_moneylink_voucher_list[index].sync_datetime = changeDateTimeFormat(new Date())
                        this.props.dispatch(setTempVoucherList(temp_moneylink_voucher_list))
                        // this.props.dispatch(setMoneyLinkTempVoucherList(temp_moneylink_voucher_list))

                    }
                })
            }
        }
    }
    handleVoucherChange = (e) =>{
        const {name,value} = e.target
        if(name == 'voucher_type_guid'){
            this.setState({ [name]: value,  }, () => {
                const voucher_details = this.props.voucherTypeList.filter(value => value.guid == this.state.voucher_type_guid)
                this.props.editVouchertype(voucher_details[0],this.props.index)
            })
        }
    }

    handleChange = (e) => {
        const { name, value, checked } = e.target
        if (name === "is_accounting_narration") {
            if (checked) {
                this.setState({ [name]: checked, accounting_narration: this.props.narration }, () => {
                    this.handleAccountingNarration()
                })
            } else {
                this.setState({ [name]: checked, accounting_narration: '' }, () => {
                    this.handleAccountingNarration()
                })
            }
        } else {

            this.setState({ [name]: value }, () => {
                this.handleAccountingNarration()
                // if(!window.location.pathname.includes('snapshot/evidence/create/')){
                //     if(this.props.temp_voucher_list[this.state.index_no].sync_status != 0)
                //         this.isLedgerModified()
                // }
            })
        }
    }

    handleAccountingNarration = (e) => {
        this.props.updateAccountingNarration(this.state.accounting_narration, this.props.voucher_id, this.props.index)
    }

    onAddLedgerClick = (type) => {
        switch (type) {
            case 1: this.props.transactionType == 1 ? this.addLedger(2) : this.addLedger(1);
                break;
            case 2: this.addLedger(1); // no change
                break;
            case 3: this.addLedger(1); // no change
                break;
            case 4: this.addLedger(1);
                break;
            case 5: this.addLedger(1);
                break;
            case 6: this.addLedger(1);
                break;
        }
    }

    addLedger = (type = 1) => {
        let amt = '';
        // if (this.props.voucherNos == 1 && this.props.ledgers.length < 2) {
        //     amt = this.props.transactionAmount
        // } else {
        //     amt = '';
        // }

        //START - To show default value as total ammount for new voucher's first ledger & second ledger
        if (this.props.voucherNos > 0 && this.props.ledgers.length < 2) {
            amt = this.props.transactionAmount
        } else {
            amt = this.remainingLedgerAmount(this.props.ledgers, type)
        }
        //END - To show default value as total ammount for new voucher's first ledger & second ledger

        let temp_ledger = {
            "amount": amt,
            "ledger_id": 0,
            "guid": "",
            "name": "",
            "cost_categories": [],
            "transaction_voucher_ledger_id": 0,
            "voucher_ledger_id": 0,
            "ledger_id": 0,
            "type": type,
            "billwise_allocation": []
        }
        let temp_ledgers_list = this.props.ledgers;
        temp_ledgers_list.push(temp_ledger)
        this.props.updateVoucher(temp_ledgers_list, this.props.voucher_id, this.props.index, null)
        this.calculateCRDR(temp_ledgers_list)
    }

    //To show remaining values of cr/dr ledger
    remainingLedgerAmount = (ledgers, type) => {
        let amtCr = 0
        let amtDr = 0
        let usedAmtCr = 0
        let usedAmtDr = 0

        ledgers.map((ledger, index) => {
            if (ledger.type == 1) {
                usedAmtCr = Number(usedAmtCr) + Number(ledger.amount)
                amtCr = (this.props.transactionAmount <= usedAmtCr) ? 0 : this.props.transactionAmount - usedAmtCr
            }
            else if (ledger.type == 2) {
                usedAmtDr = Number(usedAmtDr) + Number(ledger.amount)
                amtDr = (this.props.transactionAmount <= usedAmtDr) ? 0 : this.props.transactionAmount - usedAmtDr
            }
        })
        return (type == 1) ? amtCr.toFixed(2) : amtDr.toFixed(2)
    }

    calculateCRDR = (temp_ledgers_list, type) => {
        let cr_total = 0, dr_total = 0;
        temp_ledgers_list.forEach(function (ledger) {
            if (ledger.amount != "") {
                if (ledger.type == 1)
                    cr_total = parseFloat(ledger.amount) + parseFloat(cr_total);
                if (ledger.type == 2)
                    dr_total = parseFloat(ledger.amount) + parseFloat(dr_total);
            }
        });
        this.setState({
            total_credit_amount: rounding(parseFloat(cr_total)) || '',
            total_debit_amount: rounding(parseFloat(dr_total)) || ''
        }, () => {
            if (this.state.total_credit_amount !== this.state.total_debit_amount) {
                this.setState({ voucherErrorMessage: "*Total credit amount should be equal to debit amount." })
            } else {
                this.setState({ voucherErrorMessage: "" })

            }
        })
        this.props.updateTotal(parseFloat(cr_total), parseFloat(dr_total), this.props.voucher_id, this.props.index, type)

    }

    removeLedger = (index) => { //alert(index)
        let temp_ledgers_list = [..._.cloneDeep(this.props.ledgers)];
        if (temp_ledgers_list.transaction_voucher_ledger_id !== 0)
            this.props.removeLedger(temp_ledgers_list[index].transaction_voucher_ledger_id)

        //console.log(temp_ledgers_list)
        temp_ledgers_list.splice(index, 1);
        //console.log(temp_ledgers_list)
        this.props.updateVoucher(temp_ledgers_list, this.props.voucher_id, this.props.index, null)
        this.calculateCRDR(temp_ledgers_list)
    }

    updateLedger = (ledger, index, type = null) => {
        let temp_ledgers_list = [...this.props.ledgers];
        temp_ledgers_list[index] = ledger;
        console.log(temp_ledgers_list)
        this.props.updateVoucher(temp_ledgers_list, this.props.voucher_id, this.props.index, type)
        this.calculateCRDR(temp_ledgers_list, type)
    }

    updateBillwiseAllocation = () => {
        let temp_ledgers_list = [...this.props.ledgers];
        temp_ledgers_list[index] = ledger;
    }

    addEmptyDefaultLedger = () => {
        if (this.props.is_app_loading === false) {
            if (this.props.parent_voucher_type_id == 2) {
                this.addLedger(2);
                this.addLedger(1); 
            }
            else if(this.props.parent_voucher_type_id == 3){
                this.addLedger(1),
                this.addLedger(2)
                
            }
            else if(this.props.parent_voucher_type_id == 4){
                this.addLedger(2);
                this.addLedger(1); 
            }
            else if (this.props.parent_voucher_type_id == 1) {
                this.addLedger(1);
                this.addLedger(2);
            } else if (this.props.parent_voucher_type_id == 5) {
                this.addLedger(2),
                    this.addLedger(1)
            } else {
                this.addLedger(1),
                    this.addLedger(2)
            }
        } else {
            return null
        }
    }

    getSyncStatus = (status) => {
        if (status == 0) {
            return <OverlayTrigger overlay={<Tooltip id="tooltip-disabled"><span className="s-sbold">Not Synced</span><br />{displayDateTimeFormat(this.props.sync_datetime)}</Tooltip>}>
                <span class="icon-personal-icon ledger-ls-icon"><span class="path1 gray-icon"></span><span class="path2"></span><span class="path3"></span></span>
            </OverlayTrigger>
        } else if (status == 1) {
            return <OverlayTrigger overlay={<Tooltip id="tooltip-disabled"><span className="s-sbold">Send to Sync</span><br />{displayDateTimeFormat(this.props.sync_datetime)}</Tooltip>}>
                <span class="icon-personal-icon ledger-ls-icon"><span class="path1 blue-icon"></span><span class="path2"></span><span class="path3"></span></span>
            </OverlayTrigger>
        } else if (status == 2) {
            return <OverlayTrigger overlay={<Tooltip id="tooltip-disabled"><span className="s-sbold">Synced</span><br />{displayDateTimeFormat(this.props.sync_datetime)}</Tooltip>}>
                <span class="icon-personal-icon ledger-ls-icon"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>
            </OverlayTrigger>
        } else if (status == 3) {
            return <OverlayTrigger overlay={<Tooltip id="tooltip-disabled"><span className="s-sbold">Sync Error</span><br />{displayDateTimeFormat(this.props.sync_datetime)}<br />Error: {this.props.sync_error}</Tooltip>}>
                <span class="icon-personal-icon ledger-ls-icon"><span class="path1 red-icon"></span><span class="path2"></span><span class="path3"></span></span>
            </OverlayTrigger>
        }
    }

    render() {
        console.log(this.props.ledgers)
        const read_only = this.props.read_only;

        let note = "";
        switch (parseInt(this.props.parent_voucher_type_id)) {
            case 1: note = "Credit and Debit type should be from Cash/Bank/Bank O/D Group.";
                break;
            case 2: note = "Credit and Debit Type should be from Cash/Bank/Bank O/D Group and should not be from Cash/Bank/Bank O/D Group respectively.";
                break;
            case 3: note = "Debit and Credit Type should be from Cash/Bank/Bank O/D Group and should not be from Cash/Bank/Bank O/D Group respectively.";
                break;
            case 4: note = "Credit and Debit Type should not be from Bank/Purchase/Sales Accounts.";
                break;
            case 5: note = "Credit and Debit type should be from Sales/Tax/Duties Accounts and Party Accounts respectively.";
                break;
            case 6: note = "Debit and Credit type should be from Sales/Tax/Duties Accounts and Party Accounts respectively.";
                break;
        }
        // console.log(this.props.old_account_status)
        //console.log(this.props.ledgers)
        return (
            // <div className="main-ledger-entery-sec" key={this.props.key} ref={this[`wrapperRef${this.props.voucher_id}`]}>
            <div className="ledger-entery-new" key={this.props.key} ref={this[`wrapperRef${this.props.voucher_id}`]}>

                {(this.state.is_permission_granted && !read_only) &&
                    <span class="ss-cross" onClick={() => this.props.removeVoucher(this.props.voucher_id, this.props.index)}>
                        <span class="icon-Cross-with-grey-circle size-xxii"><span class="path1"></span><span class="path2 cpointer"></span><span class="path3"></span></span>
                    </span>
                }

                {/* <div className="row ft-sz-16 ledger-voucher mb-2"> */}
                <div className="row">
                    <div className="col-xl-8 col-lg-7 voucher-type-line">
                        <span className="s-reg gray-text mr-1 vt-text">Voucher Type:</span>
                        {/* <span className="s-sbold blue-text vt-text">{this.state.vochers_type[this.props.voucher_type]}</span> */}
                        <span className="s-sbold blue-text vt-text">
                            <Form>
                                    <Form.Group controlId="exampleForm.ControlSelect1" className="mb-0">
                                    <Form.Control as="select" disabled={(this.props.ledgerNameList.ledger_list !== undefined && this.props.ledgerNameList.ledger_list.length === 0) ? true : false} name="voucher_type_guid" value={this.state.voucher_type_guid}
                                        onChange={this.handleVoucherChange} >
                                        {
                                             this.props.voucherTypeList.filter(value => value.parent_voucher_type_id == this.props.parent_voucher_type_id).map((value,index)=>{
                                                        return (
                                                                     <option key={index} value={value.guid}>
                                                                                {value.name}
                                                                                    </option>
                                                                                );
                                                                            })
                                        }
                                    </Form.Control>
                                    </Form.Group>
                            </Form>
                        </span>
                        {this.props.location.pathname.includes('/snapshot/evidence/create') ? this.getSyncStatus(parseInt(this.props.temp_snap_voucher_list[this.state.index_no].sync_status)) : this.getSyncStatus(parseInt(this.props.temp_moneylink_voucher_list[this.state.index_no].sync_status))}
                    </div>

                    <div className="col-xl-4 col-lg-5 text-right">
                        <div className="form-group d-lg-flex justify-content-end mb-0">
                            <div className="date-width-130">
                                <ReactDatePicker
                                    dateFormat={DatePickerFormate()}
                                    dateFormat="dd-MM-yyyy"
                                    onChange={date => {
                                        this.setState({ 'voucher_creation_date': date })
                                        this.props.editVoucherDate(date, this.props.index)
                                    }
                                    }
                                    className="form-control datepicker-icon"
                                    selected={this.state.voucher_creation_date}
                                    autoComplete="off"
                                    placeholder="Voucher Date"
                                    name="voucher_creation_date"
                                    disabled={this.props.read_only}
                                    showMonthDropdown
                                    showYearDropdown
                                    scrollableYearDropdown />
                            </div>
                        </div>
                    </div>
                </div>

                <small className="error_right">
                    {(this.props.is_valid_voucher === false) && this.state.voucherErrorMessage}
                </small>

                {/* <div class="size-xvi s-reg gray-text mt-2 mb-3"><b>Note:</b> {note}</div> */}

                <div className="table-responsive main-invoice-item-new">
                    {/* <Table responsive> */}
                    <Table className="ledger-table">
                        <thead>
                            <tr>
                                <th>CR/DR</th>
                                <th className="w-50">Ledger</th>
                                <th className="text-right">Debit</th>
                                <th className="text-right">Credit</th>
                                {/* <th className="blue-color"></th> */}
                                {/* {this.state.is_permission_granted &&  <th></th>} */}
                                {this.state.is_permission_granted}
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.ledgers.length !== 0 ?
                                this.props.ledgers.map((ledger, index1) => {
                                     return <LedgerView
                                        key={"ledger_" + index1}
                                        ledger_index={index1}
                                        amount={ledger.amount}
                                        is_leder_recommended={!!this.props.is_leder_recommended}
                                        guid={ledger.guid}
                                        ledger_name={ledger.name}
                                        cost_categories={ledger.cost_categories}
                                        ledgers={this.props.ledgers}
                                        transaction_voucher_ledger_id={ledger.transaction_voucher_ledger_id}
                                        type={ledger.type}
                                        removeLedger={this.removeLedger}
                                        updateLedger={this.updateLedger}
                                        ledgerNameList={this.props.ledgerNameList}
                                        is_valid_ledger={areGuidsUnique(this.props.ledgers)?false: this.props.is_valid_ledger}
                                        read_only={this.props.read_only}
                                        transactionAmount={this.props.transactionAmount}
                                        voucherNos={this.props.voucherNos}
                                        voucher_type={this.props.parent_voucher_type_id}
                                        submit_click={this.props.submit_click}
                                        remainingLedgerAmount={this.remainingLedgerAmount}
                                        isFormDirty={this.props.isFormDirty}
                                        billwise_allocation={ledger.billwise_allocation}
                                        account_status={this.props.old_account_status}
                                        temp_linked_evidences={this.props.temp_linked_evidences}
                                        transaction_id={this.props.transaction_id}
                                        amount_round_off = {this.props.amount_round_off}
                                    />
                                })
                                :
                                this.addEmptyDefaultLedger()
                            }
                            <tr>
                                <td className="va-middle">
                                    {(this.state.is_permission_granted && !read_only) &&
                                        // <button className="item-increment border-0 bg-transparent removebrd mt-0" onClick={() => this.onAddLedgerClick(1)} >
                                        //     <span className="icon-plus"></span>
                                        // </button>

                                        // <span class="icon-plus-circled ledger-add-row" onClick={() => this.onAddLedgerClick(1)}></span>

                                        <button className="ledger-add-row" onClick={() => this.onAddLedgerClick(1)}>
                                            <span class="icon-plus-circled"></span>
                                        </button>
                                    }
                                </td>
                                <td className="ledger-total">
                                    Total (₹)
                                </td>
                                <td>
                                    <CurrencyInput
                                        type="text"
                                        // className={"form-control text-right input-bg-gray"}
                                        className={"form-control text-right red-color"}
                                        name="total_amount"
                                        value={(this.state.total_debit_amount || 0)}
                                        disabled={true}
                                        decimalsLimit={2}
                                        prefix="&#8377; "
                                        placeholder='&#8377; 0'
                                    />
                                </td>
                                <td>
                                    <CurrencyInput
                                        type="text"
                                        // className={"form-control text-right input-bg-gray"}
                                        className={"form-control text-right green-color"}
                                        name="total_amount"
                                        value={(this.state.total_credit_amount || 0)}
                                        disabled={true}
                                        decimalsLimit={2}
                                        prefix="&#8377; "
                                        placeholder='&#8377; 0'
                                    />
                                </td>

                                {/* <td className="text-right croslt"></td> */}
                            </tr>
                        </tbody>
                    </Table>
                </div>

                <div className="row">
                    <div className="col-sm-12">
                        <div className="form-main-title-new pb-2 mb-2">
                            {/* <h4 className="form-title-heading-new">Accounting Narration</h4> */}
                            <h5 className="form-title-heading-new size-xviii">Accounting Narration</h5>
                            {(this.state.is_permission_granted && !read_only) &&
                                <div className="toggle-switch-btn toggle-switch-center">
                                    <span className="toggle-switch-center">
                                        <span className="toggle-btn-sec-text">No</span>
                                        <label className="switch">
                                            <input type="checkbox"
                                                checked={this.state.is_accounting_narration}
                                                name="is_accounting_narration"
                                                onChange={this.handleChange}
                                            />
                                            <span className="slider round"></span>
                                        </label>
                                        <span className="toggle-btn-sec-text">Yes</span>
                                    </span>
                                </div>
                            }

                        </div>
                    </div>
                </div>
                {this.state.is_accounting_narration &&
                    <Form.Group controlId="exampleForm.ControlTextarea1" className="mb-2">
                        <Form.Control as="textarea" rows={2} value={this.state.accounting_narration} readOnly={!this.state.is_permission_granted || read_only}
                            name="accounting_narration" onChange={this.handleChange} onBlur={this.handleAccountingNarration} />
                    </Form.Group>
                }


                {/* <div className="main-accounting-narration-sec">
                    <div className={"clearfix bottomborder " + (this.state.is_accounting_narration == true ? "top-head mb-2" : " ")}>
                        <p className="float-lg-left s-sbold ft-sz-20 blu-color m-0 ">Accounting Narration</p>
                        {(this.state.is_permission_granted && !read_only) && <div className="toggle-btn-sec float-lg-right d-flex mb-0 pb-0 pr-0">
                            <span className="toggle-btn-sec-text s-sbold blue-color">No</span>
                            <label className="switch">
                                <input type="checkbox" checked={this.state.is_accounting_narration}
                                    name="is_accounting_narration" onChange={this.handleChange} />
                                <span className="slider round"></span>
                            </label>
                            <span className="toggle-btn-sec-text s-sbold blue-color">Yes</span>
                        </div>}
                    </div>
                    {this.state.is_accounting_narration && <Form.Group controlId="exampleForm.ControlTextarea1" className="mb-0">
                        <Form.Control as="textarea" rows={2} value={this.state.accounting_narration} readOnly={!this.state.is_permission_granted || read_only}
                            name="accounting_narration" onChange={this.handleChange} onBlur={this.handleAccountingNarration} />
                    </Form.Group>}
                </div> */}
            </div>
        )
    }
}

function mapStateToProps(state) {
    const is_app_loading = state.App.is_app_loading || false;
    const all_value = state.MoneyLink || {};

    const session_value = state.Session || {};
    const features_permissions_list = session_value.features_permissions_list || [];

    const temp_voucher_list = all_value.temp_voucher_list;
    const temp_moneylink_voucher_list = all_value.temp_voucher_list || [];
    const temp_linked_evidences = all_value.temp_linked_evidences;

    const snapshot = state.Snapshot || {};
    const temp_snap_voucher_list = snapshot.temp_snap_voucher_list || [];

    //console.log(temp_snap_voucher_list)

    return {
        is_app_loading, features_permissions_list, temp_voucher_list, temp_snap_voucher_list, temp_moneylink_voucher_list, temp_linked_evidences
    };
}

export default connect(mapStateToProps)(withRouter(VoucherCardView));