import React from 'react'
import { Bar } from 'react-chartjs-2';
import Modal from 'react-bootstrap/Modal';
import { entries } from 'lodash';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { showRoundOffAmount } from '../../../Helpers/Currency';
import { dashboardTooltip } from '../../../Helpers/HelperFunction';

const moment = require('moment')

const BarChartSummary = (props) => {
  let balance = 0
  var month = [];
  let date = [];
  let mergeEntries = []
  const [buktecSummary3, buktecsetShow3] = React.useState(false);
  let split_type = props.split_type ? 'month' : 'week';
  let inflow = 0;
  let outflow = 0;
  var type1_wise = []
  let type2_wise = []
  let array1 = []
  let array2 = []

  props.data.map((item) => {
    //financial_institutes.map((item) => {
    date.push(item.latest_date)
    balance = balance + item.balance

    return item.data.map((obj) => {
      if (!month.includes(obj[split_type])) {
        month.push(obj[split_type])
      }
    })
  })

  month.map((xlable) => {
    type1_wise[xlable] = []
    type2_wise[xlable] = []
    //financial_institutes.map((item) => {
    props.data.map((item) => {
      item.data.map((obj) => {
        obj.entries.map((entry) => {
          if (xlable == obj[split_type]) {
            if (entry.type == 1) {
              inflow = inflow + entry.amount
              type1_wise[xlable].push(entry.amount)
            }

            if (entry.type == 2) {
              outflow = outflow + entry.amount
              type2_wise[xlable].push(entry.amount)
            }
          }
        })
      })
    })
  })

  console.log(type1_wise)

  // month.map((xlable) => { 
  //   type1_wise.map((i) => { 
  //     if(i.hasOwnProperty(xlable)) {

  //     }
  //   })
  // })

  for (const [key, value] of Object.entries(type1_wise)) {
    let total = value.reduce((a, b) => a + b, 0)
    array1.push(total)
  }

  for (const [key, value] of Object.entries(type2_wise)) {
    let total = value.reduce((a, b) => a + b, 0)
    array2.push(total)
  }


  const getLatestDate = () => {
    date = date.sort()
    return date[date.length - 1]
  }

  return (
    <>
      <div className="col-xl-8 col-lg-10 col-md-12">
        <div className="chart-box-new">
          <h4 className="chart-box-heading">Financial Institute Summary</h4>

          {/* row */}
          <div className="row">
            {/* <div className="col-xl-10 col-lg-10 col-md-9"> */}
            <div className="chart-left-col">
              <div className="chart-div" onClick={() => buktecsetShow3(true)}>
                <Bar
                  data={{
                    labels: month,
                    datasets: [
                      {
                        label: 'Inflow',
                        data: array1,
                        backgroundColor: props.color1_background,
                      },
                      {
                        label: 'Outflow',
                        data: array2,
                        backgroundColor: props.color2_background
                      }
                    ],
                  }}
                  options={
                    {
                      plugins: {
                        legend: false,
                      },
                      // tooltips: {
                      //   callbacks: {
                      //     label: function(tooltipItem, data) {
                      //       var value = data.datasets[0].data[tooltipItem.index];                          
                      //       value = Math.round(value);
                      //       value = value.toString();
                      //       value = value.split(/(?=(?:...)*$)/);
                      //       value = value.join(',');
                      //       return "₹ " +value;
                      //     }
                      //   } // end callbacks:
                      // }, //end tooltips
                      scales: {
                        yAxes: [{
                          ticks: {
                            beginAtZero: true
                            // userCallback: function(value, index, values) {
                            //   value = Math.round(value);
                            //   value = value.toString();
                            //   value = value.split(/(?=(?:...)*$)/);
                            //   value = value.join(',');
                            //   return "₹ " +value;
                            // }
                          }
                        }],
                        xAxes: [
                          {
                            ticks: {
                              callback: (label, index, labels) => {
                                //if(label) {
                                return label.length > 8 ? label.substr(0, 8) + '...' : label;
                                //}
                              },
                            }
                          }
                        ]
                      }
                    }
                  }
                />
              </div>
            </div>

            <div className="chart-right-col">
              <div className="chart-content">
                <p className="chart-lable"><span className="chart-tag-color" style={{ background: props.color1_background }}></span> Inflow</p>
                <p className="chart-cp">
                  <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{showRoundOffAmount(inflow)}</Tooltip>}>
                    <span className='chart-price'>{showRoundOffAmount(inflow)}</span>
                  </OverlayTrigger>
                  <span class="icon-Green-arrow chart-price-icon"></span>
                </p>

                <p className="chart-lable"><span className="chart-tag-color" style={{ background: props.color2_background }}></span> Outflow</p>
                <p className="chart-cp">
                  <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{showRoundOffAmount(outflow)}</Tooltip>}>
                    <span className='chart-price'>{showRoundOffAmount(outflow)} </span>
                  </OverlayTrigger>
                  <span class="icon-Red-arrow chart-price-icon"></span>
                </p>

                <p className="chart-lable">Balance ({getLatestDate()})</p>
                <p className="chart-cp">
                  <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{showRoundOffAmount(balance)}</Tooltip>}>
                    <span className='chart-price'>{showRoundOffAmount(balance)}</span>
                  </OverlayTrigger>
                </p>
              </div>
            </div>
          </div>
          {/* end row */}
        </div>
        {/* End bar-chart-box-new */}
      </div>

      <Modal
        show={buktecSummary3}
        // size="lg"
        onHide={() => buktecsetShow3(false)}
        // dialogClassName="chart-model-width"
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        centered
        className="popup-model chart-box-popup"
      >
        <Modal.Header closeButton>
          <Modal.Title>Financial Institute Summary</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="chart-box-new model-chart-box-new">
            <div className="row">
              {/* <div className="col-xl-10 col-lg-10 col-md-9"> */}
              <div className="model-chart-left-col">
                <div className="chart-div">
                  <Bar
                    data={{
                      labels: month,
                      datasets: [
                        {
                          label: 'Inflow',
                          data: array1,
                          backgroundColor: props.color1_background,
                        },
                        {
                          label: 'Outflow',
                          data: array2,
                          backgroundColor: props.color2_background
                        }
                      ],
                    }}
                    options={
                      {
                        plugins: {
                          legend: false,
                        },
                        // tooltips: {
                        //   callbacks: {
                        //     label: function(tooltipItem, data) {
                        //       var value = data.datasets[0].data[tooltipItem.index];                          
                        //       value = Math.round(value);
                        //       value = value.toString();
                        //       value = value.split(/(?=(?:...)*$)/);
                        //       value = value.join(',');
                        //       return "₹ " +value;
                        //     }
                        //   } // end callbacks:
                        // }, //end tooltips
                        scales: {
                          yAxes: [{
                            ticks: {
                              beginAtZero: true
                              // userCallback: function(value, index, values) {
                              //   value = Math.round(value);
                              //   value = value.toString();
                              //   value = value.split(/(?=(?:...)*$)/);
                              //   value = value.join(',');
                              //   return "₹ " +value;
                              // }
                            }
                          }],
                          xAxes: [
                            {
                              ticks: {
                                callback: (label, index, labels) => {
                                  //if(label) {
                                  return label.length > 8 ? label.substr(0, 8) + '...' : label;
                                  //}
                                },
                              }
                            }
                          ]
                        }
                      }
                    }
                  />
                </div>
              </div>

              <div className="model-chart-right-col">
                <div className="chart-content model-chart-padding">
                  <p className="chart-lable"><span className="chart-tag-color" style={{ background: props.color1_background }}></span> Inflow</p>
                  <p className="chart-cp model-chart-cp">
                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{showRoundOffAmount(inflow)}</Tooltip>}>
                      <span className='chart-price'>{showRoundOffAmount(inflow)}</span>
                    </OverlayTrigger>
                    <span class="icon-Green-arrow chart-price-icon"></span>
                  </p>

                  <p className="chart-lable"><span className="chart-tag-color" style={{ background: props.color2_background }}></span> Outflow</p>
                  <p className="chart-cp model-chart-cp">
                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{showRoundOffAmount(outflow)}</Tooltip>}>
                      <span className='chart-price'>{showRoundOffAmount(outflow)} </span>
                    </OverlayTrigger>
                    <span class="icon-Red-arrow chart-price-icon"></span>
                  </p>

                  <p className="chart-lable">Balance ({getLatestDate()})</p>
                  <p className="chart-cp model-chart-cp">
                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{showRoundOffAmount(balance)}</Tooltip>}>
                      <span className='chart-price'>{showRoundOffAmount(balance)}</span>
                    </OverlayTrigger>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default BarChartSummary