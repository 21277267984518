import React from 'react'
import { Link, withRouter } from 'react-router-dom';
import { Accordion, Card, Form, Table, Button } from 'react-bootstrap';
import Select from 'react-select';
import CurrencyInput from 'react-currency-input-field';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import StorageData from "../../../Helpers/StorageData";
import BreadCrum from '../../../Components/BoilerPlate/BreadCrum';
import { showAmount, rounding } from '../../../Helpers/Currency';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import _, { values } from "lodash";
import TransactionCard from './TransactionCard';
import MoneyLinkService from '../../../Services/MoneyLink/MoneyLinkService';
import { validateSaveBulkLedger } from './Validation';
import { setBulkTransactionList, setCostCategoryList } from "../../../Redux/Actions/MoneyLink";
import { setAppilcationLoder } from '../../../Redux/Actions/App';
import RouteLeavingGuard from '../../../Utils/RouteLeavingGuard';
import { decodeHtml } from '../../../Helpers/HelperFunction';
import CostCategory from '../../../Components/BoilerPlate/CostCategory';
import { confirm } from '../../../Components/BoilerPlate/Confirm';
import { Steps } from "intro.js-react";
import AppliedFilterTag from '../../../Components/BoilerPlate/AppliedFilterTag';
import { displayDateFormate } from '../../../Helpers/DateFormat';


const SelectSearch = {
	control: styles =>
	({
		...styles,
		color: '#2C275F!important',
		borderColor: '#707070',
		boxShadow: '0px !important',
		// fontSize : '17px',
		fontFamily: 's-sbold !important',

		'&:focus': {
			borderColor: '#77d7b1 !important',
			boxShadow: '1px #77d7b1 !important',
		},
		'&:hover': {
			borderColor: '#77d7b1 !important',
			boxShadow: '0px !important',
		},

	}),
	placeholder: styles => ({ ...styles, color: '#2C275F' }),
}

class BulkLedgerForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			credit_amt: 0,
			debit_amt: 0,
			is_accounting_narration: false,
			accounting_narration: "",
			voucher_type: "",
			cr_ledger_id: {},
			dr_ledger_id: {},
			cr_ledg_id: "",
			dr_ledg_id: "",
			total_debit_amount: "",
			total_credit_amount: "",
			ledger_options: [],
			ledger_list: [],
			last_updated_at: null,
			sync_status: null,
			sync_error: null,
			errors: {},
			is_dirty: false,
			closed: false,
			next_route: false,
			cr_cost_categories: [],
			dr_cost_categories: [],
			cost_categories_list: [],
			is_valid_ledger: true,

			// Intro js user guid
			// this.state = {
			stepsEnabled: false,
			initialStep: 0,
			steps: [
				{
					element: ".ledgerbf_moneyin",
					intro: "Bulk Ledger section allows the accountant to post multiple entries of similar nature. You will see the total of all the entries here. The same will get exported as individual entries to your accounting software.",
					// position: 'right',
					// hideNext: true 
				},
				{
					element: ".savebf_moneyin",
					intro: "You can save the page by clicking here.",
					position: 'left',
				},
			],
			// };
			// End Intro js user guid
			is_ledger_recommended: false,
			voucherTypeList:[],
			voucher_type_object : {},
			voucher_type_guid:""
		}
	}
	getWidgetFilter = () => {
		switch (this.props.filter_card) {
			case 1: return "Draft"
				break;
			case 2: return "Ready"
				break;
			case 3: return "Pending"
				break;
			case 4: return "Accounted"
				break;
			case 5: return "Synced"
				break;
			case 7: return "Linked"
				break;
			case 8: return "Not Linked"
				break;
			default: return "";
		}
	}
	getDateRange = () => {
		let default_yr = StorageData.getDefaultFinancialYear();
		if (this.props.financial_year_name === "Custom Date Range") {
			return displayDateFormate(default_yr.start_date) + ' to ' + displayDateFormate(default_yr.end_date)
		} else {
			return this.props.financial_year_name
		}
	}

	amountChange = (value, name) => {
		this.setState({ [name]: value, is_dirty: true })
	}

	handleLedgerChange = (value, name) => {
		this.setState({ [name]: value, is_dirty: true })
		let ledger = this.state.ledger_list.find(ledger => ledger.guid == value.value);
		if (name == "cr_ledger_id") {
			this.setState({ cr_cost_categories: [] }, () => {
				if (ledger.has_cost_centre)
					this.onAddCategory("cr");
			});
		} else {
			this.setState({ dr_cost_categories: [] }, () => {
				if (ledger.has_cost_centre)
					this.onAddCategory("dr");
			});

		}
	}

	removeCostCategory = (index, type) => {
		if (type == "cr") {
			let cr_cost_categories = this.state.cr_cost_categories;
			cr_cost_categories.splice(index, 1);
			this.setState({ cr_cost_categories: [] }, () => {
				this.setState({ cr_cost_categories: cr_cost_categories });
			})
		} else {
			let dr_cost_categories = this.state.dr_cost_categories;
			dr_cost_categories.splice(index, 1);
			this.setState({ dr_cost_categories: dr_cost_categories }, () => {
				this.setState({ dr_cost_categories: dr_cost_categories });
			})
		}
	}

	onAddCategory = (type) => {

		if (type == "cr") {
			let cr_cost_categories = this.state.cr_cost_categories;
			let cost_category = {
				"category_guid": "",
				"category_id": 0,
				"category_name": "",
				// "transaction_voucher_ledger_cost_category_id":0,
				"cost_centers": [{
					"cost_center_guid": "",
					"cost_center_id": 0,
					"cost_center_name": "",
					"cost_center_amount": this.state.total_credit_amount,
					// "transaction_voucher_ledger_cost_category_cost_centre_id":0
				}]
			}
			cr_cost_categories.push(cost_category)
			this.setState({ cr_cost_categories: cr_cost_categories }, () => {
				// this.setState({cr_cost_categories:cr_cost_categories});
				// let temp_ledger = this.props.ledgers[this.props.ledger_index];
				// temp_ledger.cost_categories = cost_categories;
				// this.props.updateLedger(temp_ledger, this.props.ledger_index)
			})
		} else {
			let dr_cost_categories = this.state.dr_cost_categories;
			let cost_category = {
				"category_guid": "",
				"category_id": 0,
				"category_name": "",
				// "transaction_voucher_ledger_cost_category_id":0,
				"cost_centers": [{
					"cost_center_guid": "",
					"cost_center_id": 0,
					"cost_center_name": "",
					"cost_center_amount": this.state.total_debit_amount,
					// "transaction_voucher_ledger_cost_category_cost_centre_id":0
				}]
			}
			dr_cost_categories.push(cost_category)
			this.setState({ dr_cost_categories: dr_cost_categories }, () => {
				// this.setState({dr_cost_categories:dr_cost_categories});
				// let temp_ledger = this.props.ledgers[this.props.ledger_index];
				// temp_ledger.cost_categories = cost_categories;
				// this.props.updateLedger(temp_ledger, this.props.ledger_index)
			})
		}
	}

	handleChange = (e) => {
		const target = e.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
		this.setState({ [name]: value, is_dirty: true });
	}
	handleChangeVoucherType = async(e)=>{
		this.setState({voucher_type_guid:e.target.value,is_dirty:true},()=>{
			let voucher_type_object = {}
			if(e.target.value == ""){
				voucher_type_object.parent_voucher_type_id = ""
				voucher_type_object.voucher_type_guid= ""
				voucher_type_object.voucher_type_name= ""
				voucher_type_object.voucher_type_id= ""
			}else{
				const details = this.state.voucherTypeList.filter((voucher) => voucher.guid == e.target.value)
				if(details.length > 0){
					voucher_type_object.parent_voucher_type_id =details[0].parent_voucher_type_id
					voucher_type_object.voucher_type_guid= details[0].guid
					voucher_type_object.voucher_type_name= details[0].name
					voucher_type_object.voucher_type_id= details[0].id
				}
			}
			this.setState({voucher_type_object})
		})

		let expense_no = ["3", "5"];
		let expense_yes = ["2", "6"];


		let expense_yes_present = false;
		let expense_no_present = false;
		const voucher_details = this.state.voucherTypeList.filter((voucher) => voucher.guid == e.target.value)

		if (expense_no.includes(voucher_details[0].parent_voucher_type_id.toString())) {
			expense_no_present = true;
		  }
  
		  if (expense_yes.includes(voucher_details[0].parent_voucher_type_id.toString())) {
			expense_yes_present = true;
		  }
		if (voucher_details[0].parent_voucher_type_id != 1 && voucher_details[0].parent_voucher_type_id != 4) {
			if (expense_yes_present && expense_no_present || (expense_no.includes(voucher_details[0].parent_voucher_type_id.toString()))) {
			  await confirm({
				confirmation: "Conflicting voucher types found.",
				options: { type: "alert" }
			  })
			  return
			}
		  }
  

	}

	returnCRDataValue(i,cr_ledger_detail,cr_cost_categories_avail){
		return {
			"amount": this.props.bulk_transaction_list[i].amount,
			"ledger_id": cr_ledger_detail == undefined ? this.state.cr_ledg_id : cr_ledger_detail.id,
			"guid": cr_ledger_detail == undefined ? this.state.cr_ledger_id.value : cr_ledger_detail.guid,
			"name": cr_ledger_detail == undefined ? decodeHtml(this.state.cr_ledger_id.label) : cr_ledger_detail.name,
			"type": 1,
			"cost_categories": !cr_cost_categories_avail ? [] : this.state.cr_cost_categories.map((category, category_index) => {
				if (!category.category_id || !category.category_guid || !category.category_name) {
					is_valid_ledger = false;
				}
				let temp_category = {
					"cost_category_id": category.category_id,
					"guid": category.category_guid,
					"name": category.category_name,
					"cost_centres": category.cost_centers.map((cost_center, cc_index) => {
						if (!cost_center.cost_center_name || !cost_center.cost_center_guid || !cost_center.cost_center_amount) {
							is_valid_ledger = false;
						}
						let tmp_cost_center = {
							"amount": this.props.bulk_transaction_list[i].amount,
							"cost_centre_id": cost_center.cost_center_id,
							"guid": cost_center.cost_center_guid,
							"name": cost_center.cost_center_name,
							"type": 1
						}
						return tmp_cost_center;
					})
				}
				return temp_category;
			})
		}
	}
	returnDRDataValue(i,dr_ledger_detail,dr_cost_categories_avail){
		return {
			"amount": this.props.bulk_transaction_list[i].amount,
			"ledger_id": dr_ledger_detail == undefined ? this.state.dr_ledg_id : dr_ledger_detail.id,
			"guid": dr_ledger_detail == undefined != 0 ? this.state.dr_ledger_id.value : dr_ledger_detail.guid,
			"name": dr_ledger_detail == undefined != 0 ? decodeHtml(this.state.dr_ledger_id.label) : dr_ledger_detail.name,
			"type": 2,
			"cost_categories": !dr_cost_categories_avail ? [] : this.state.dr_cost_categories.map((category, category_index) => {
				if (!category.category_id || !category.category_guid || !category.category_name) {
					is_valid_ledger = false;
				}
				let temp_category = {
					"cost_category_id": category.category_id,
					"guid": category.category_guid,
					"name": category.category_name,
					"cost_centres": category.cost_centers.map((cost_center, cc_index) => {
						if (!cost_center.cost_center_name || !cost_center.cost_center_guid || !cost_center.cost_center_amount) {
							is_valid_ledger = false;
						}
						let tmp_cost_center = {
							"amount": this.props.bulk_transaction_list[i].amount,
							"cost_centre_id": cost_center.cost_center_id,
							"guid": cost_center.cost_center_guid,
							"name": cost_center.cost_center_name,
							"type": 2
						}
						return tmp_cost_center;
					})
				}
				return temp_category;
			})
		}
	}
	returnLedgerData(i,dr_ledger_detail,dr_cost_categories_avail,cr_ledger_detail,cr_cost_categories_avail){
	 	if(this.state.voucher_type_object.parent_voucher_type_id == 2) 
			return [this.returnDRDataValue(i,dr_ledger_detail,dr_cost_categories_avail),this.returnCRDataValue(i,cr_ledger_detail,cr_cost_categories_avail)]
		else if(this.state.voucher_type_object.parent_voucher_type_id == 3)
			return [this.returnCRDataValue(i,cr_ledger_detail,cr_cost_categories_avail),this.returnDRDataValue(i,dr_ledger_detail,dr_cost_categories_avail)]
		else if(this.state.voucher_type_object.parent_voucher_type_id == 4)
			return [this.returnDRDataValue(i,dr_ledger_detail,dr_cost_categories_avail),this.returnCRDataValue(i,cr_ledger_detail,cr_cost_categories_avail)]		
		else if (this.state.voucher_type_object.parent_voucher_type_id == 1) 
			return [this.returnCRDataValue(i,cr_ledger_detail,cr_cost_categories_avail),this.returnDRDataValue(i,dr_ledger_detail,dr_cost_categories_avail)]
		 else if (this.state.voucher_type_object.parent_voucher_type_id == 5) 
			return [this.returnDRDataValue(i,dr_ledger_detail,dr_cost_categories_avail),this.returnCRDataValue(i,cr_ledger_detail,cr_cost_categories_avail)]
		 else 
			return [this.returnCRDataValue(i,cr_ledger_detail,cr_cost_categories_avail),this.returnDRDataValue(i,dr_ledger_detail,dr_cost_categories_avail)]	
	}
	saveVoucher = async () => {
		let errors = validateSaveBulkLedger(this.state.cr_ledger_id, this.state.dr_ledger_id, this.state.voucher_type_guid,
			this.state.is_accounting_narration, this.state.accounting_narration);
		if (Object.keys(errors).length === 0) {

			if (!await confirm({ confirmation: 'Kindly note that Bulk Entry shall mark the selected Draft or Ready transactions as Ready and Accounted. Do you wish to proceed?' })) {
				return true;
			}

			this.props.dispatch(setAppilcationLoder(true));
			let data = [];
			let is_valid_ledger = true;
			let cr_cost_categories_avail = true;
			let dr_cost_categories_avail = true;

			if (this.state.cr_cost_categories.length == 1 && !this.state.cr_cost_categories[0].category_id) {
				cr_cost_categories_avail = false;
			}
			if (this.state.dr_cost_categories.length == 1 && !this.state.dr_cost_categories[0].category_id) {
				dr_cost_categories_avail = false;
			}
			for (let i = 0; i < this.props.bulk_transaction_list.length; i++) {
				let cr_ledger_detail = this.state.ledger_list.find(ledger => ledger.guid == this.state.cr_ledger_id.value);
				let dr_ledger_detail = this.state.ledger_list.find(ledger => ledger.guid == this.state.dr_ledger_id.value);
				console.log(dr_ledger_detail)

				data.push({
					"transaction_id": this.props.bulk_transaction_list[i].id,
					"accounting_narration": this.props.bulk_transaction_list[i].narration + " | "
						+ (this.state.accounting_narration || "None") + " | Bulk Ledger Entry",
					"ledgers": this.returnLedgerData(i,dr_ledger_detail,dr_cost_categories_avail,cr_ledger_detail,cr_cost_categories_avail),
					"total_credit_amount": this.props.bulk_transaction_list[i].amount,
					"total_debit_amount": this.props.bulk_transaction_list[i].amount,
					"voucher_creation_date": this.props.bulk_transaction_list[i].date,
					// "voucher_type": this.state.voucher_type
					"parent_voucher_type_id":this.state.voucher_type_object.parent_voucher_type_id,
					"voucher_type_guid":this.state.voucher_type_object.voucher_type_guid,
					"voucher_type_name":this.state.voucher_type_object.voucher_type_name,
					"voucher_type_id":this.state.voucher_type_object.voucher_type_id,
				});
			}

			if (!is_valid_ledger) {
				this.setState({ is_valid_ledger: false, save_and_redirect: false, next_route: false })
				this.props.dispatch(setAppilcationLoder(false));
				toast.error('Ledger data is missing.')
				return;
			}

			let voucher = { "vouchers": data };
			MoneyLinkService.saveBulkLedger(voucher).then(data => {
				this.props.dispatch(setAppilcationLoder(false));
				this.setState({ is_dirty: false });
				if (data.response_code == 200) {
					toast.success('Bulk ledger list saved successfully');
					let transaction_ids = this.props.bulk_transaction_list.map(transaction => transaction.id);
					this.props.changeSyncLedgerStatus(transaction_ids);
					this.props.dispatch(setBulkTransactionList([]));
					this.props.refreshItem()
					this.props.history.goBack()
				} else {
					toast.error(data.message);
					this.setState({ errors: data.reason });
				}
			})
		} else {
			this.setState({
				errors: errors,
				save_and_redirect: false,
				next_route: false
			});
			toast.error("Wrong input provided")
		}
	}

	getLedgerNameList = (is_refresh = false) => {
		MoneyLinkService.getLedgerNameList().then(async (data) => {
			if (data.response_code == 200) {

				let ledger_options = data.result.ledger_list.map((ledger, index) => {
					return { label: decodeHtml(ledger.name), value: ledger.guid }
				})
				this.setState({
					ledger_list: data.result.ledger_list,
					ledger_options: ledger_options,
					last_updated_at: data.result.last_updated_at,
					sync_status: data.result.sync_status,
					sync_error: data.result.sync_error
				})

				if (!is_refresh) {

					if (this.props.ledger_recommendation_param == 1) {
						this.getBulkLedgerRecommendation();
					} else if (this.props.ledger_recommendation_param == 2) {
						this.props.dispatch(setAppilcationLoder(false));
						if (await confirm({
							confirmation: 'Would you like ledger recommendation?'
						})) {
							this.getBulkLedgerRecommendation();
						}
					}
				}
			}
		})
	}


	getCostcenterCategory = () => {
		MoneyLinkService.getCostcenterCategory().then(
			(data) => {
				if (data.response_code == 200) {
					let cost_categories_list = data.result.cost_categories.map((category) => { return { label: decodeHtml(category.name), value: category.guid, id: category.id } })
					this.setState({ cost_categories_list: cost_categories_list })
				} else {
					toast.error(data.message);
				}
			});
	}

	componentDidMount() {
		//this.getCostcenterCategory();
		if (this.props.bulk_transaction_list.length == 0) {
			this.props.history.push(`/money-link/bulk/` + this.props.cr_dr_type)
		} else {
			let total_amount = this.props.bulk_transaction_list.map(item => item.amount).reduce((prev, curr) => prev + curr, 0);
			this.setState({
				credit_amt: rounding(total_amount),
				debit_amt: rounding(total_amount),
				total_debit_amount: rounding(total_amount),
				total_credit_amount: rounding(total_amount)
			})
		}
		this.getLedgerNameList();
		this.getCostcenterCategory();
		this.getVoucherTypeList();
	}

	getVoucherTypeList = async () =>{
		await MoneyLinkService.getVoucherTypeList().then(data => {
			if(data.response_code == 200){
				this.setState({voucherTypeList:data.result.voucher_types})
			}
		})
	}

	getCostcenterCategory = () => {
		MoneyLinkService.getCostcenterCategory().then(
			(data) => {
				if (data.response_code == 200) {
					let cost_categories_list = data.result.cost_categories.map((category) => {
						category.label = decodeHtml(category.name);
						category.value = category.guid;
						return category;
					})
					this.props.dispatch(setCostCategoryList(cost_categories_list))
				} else {
					toast.error(data.message);
				}
			});
	}

	removeTransaction = (total_amount, bulk_transaction_list) => {
		this.setState({
			credit_amt: total_amount,
			debit_amt: total_amount,
			total_debit_amount: total_amount,
			total_credit_amount: total_amount,
			is_dirty: !!bulk_transaction_list.length
		})
	}

	closeAction = () => {
		this.setState({ closed: false })
	}

	saveBeforeLeave = (nextRoute) => {
		this.setState({ save_and_redirect: true, next_route: nextRoute }, function () {
			if (this.state.is_dirty) {
				this.setState({ is_dirty: false })
				this.saveVoucher()
			}
		});
	}

	// Intro js user guid   
	toggleSteps = () => {
		this.setState(prevState => ({ stepsEnabled: !prevState.stepsEnabled }));
	};
	onExit = () => {
		this.setState(() => ({ stepsEnabled: false }));
	};
	// End Intro js user guid

	// getSyncStatus = (status) => {
	// 	if (status == 0) {
	// 		return <OverlayTrigger overlay={<Tooltip id="tooltip-disabled"><span className="s-sbold">Not Synced</span></Tooltip>}>
	// 			<span class="icon-personal-icon mr-2 fs27"><span class="path1 gray-icon"></span><span class="path2"></span><span class="path3"></span></span>
	// 		</OverlayTrigger>
	// 	} else if (status == 1) {
	// 		return <OverlayTrigger overlay={<Tooltip id="tooltip-disabled"><span className="s-sbold">Send to Sync</span></Tooltip>}>
	// 			<span class="icon-personal-icon mr-2 fs27"><span class="path1 blue-icon"></span><span class="path2"></span><span class="path3"></span></span>
	// 		</OverlayTrigger>
	// 	} else if (status == 2) {
	// 		return <OverlayTrigger overlay={<Tooltip id="tooltip-disabled"><span className="s-sbold">Synced</span></Tooltip>}>
	// 			<span class="icon-personal-icon mr-2 fs27"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>
	// 		</OverlayTrigger>
	// 	} else if (status == 3) {
	// 		return <OverlayTrigger overlay={<Tooltip id="tooltip-disabled"><span className="s-sbold">Sync Error</span><br />Error: {this.state.sync_error}</Tooltip>}>
	// 			<span class="icon-personal-icon mr-2 fs27"><span class="path1 red-icon"></span><span class="path2"></span><span class="path3"></span></span>
	// 		</OverlayTrigger>
	// 	}
	// }

	getSyncStatus = (status) => {
		if (status == 0) {
			return <OverlayTrigger overlay={<Tooltip id="tooltip-disabled"><span>Not Synced</span></Tooltip>}>
				<span class="icon-personal-icon ledger-trs-icon"><span class="path1 gray-icon"></span><span class="path2"></span><span class="path3"></span></span>
			</OverlayTrigger>
		} else if (status == 1) {
			return <OverlayTrigger overlay={<Tooltip id="tooltip-disabled"><span>Send to Sync</span></Tooltip>}>
				<span class="icon-personal-icon ledger-trs-icon"><span class="path1 blue-icon"></span><span class="path2"></span><span class="path3"></span></span>
			</OverlayTrigger>
		} else if (status == 2) {
			return <OverlayTrigger overlay={<Tooltip id="tooltip-disabled"><span>Synced</span></Tooltip>}>
				<span class="icon-personal-icon ledger-trs-icon"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>
			</OverlayTrigger>
		} else if (status == 3) {
			return <OverlayTrigger overlay={<Tooltip id="tooltip-disabled"><span>Sync Error</span><br />Error: {this.state.sync_error}</Tooltip>}>
				<span class="icon-personal-icon ledger-trs-icon"><span class="path1 red-icon"></span><span class="path2"></span><span class="path3"></span></span>
			</OverlayTrigger>
		}
	}

	getBulkLedgerRecommendation = async () => {
		let financial_institute_id = StorageData.getDefaultFI();

		if (Object.keys(this.state.cr_ledger_id).length != 0 || Object.keys(this.state.dr_ledger_id).length != 0) {
			if (await confirm({
				confirmation: 'This action will remove existing vouchers. Do you want to continue?'
			})) {
				this.setState({
					cr_ledger_id: {},
					dr_ledger_id: {},
					cr_ledg_id: "",
					dr_ledg_id: "",
					is_ledger_recommended: false,
					// voucher_type: ""
					voucher_type_guid:"",
					voucher_type_object:{}
				})
			} else {
				return true;
			}
		}

		this.props.dispatch(setAppilcationLoder(true));

		MoneyLinkService.getBulkLedgerRecommendation({ type: this.props.cr_dr_type, financial_institute_id })
			.then(async (data) => {
				this.props.dispatch(setAppilcationLoder(false));
				if (data.response_code == 200) {
					if (Object.keys(data.result.ledgers).length == 0) {
						toast.error("No recommended Ledger Found.");
					} else {
						let response = data.result;

						let voucher_type_object = {}
						if(response.voucher_type_guid){
							const details = this.state.voucherTypeList.filter((voucher) => voucher.guid == response.voucher_type_guid)
						
							if(details.length > 0){
								voucher_type_object.parent_voucher_type_id =details[0].parent_voucher_type_id
								voucher_type_object.voucher_type_guid= details[0].guid
								voucher_type_object.voucher_type_name= details[0].name
								voucher_type_object.voucher_type_id= details[0].id
							}
						}
						else{
							voucher_type_object.parent_voucher_type_id =""
							voucher_type_object.voucher_type_guid= ""
							voucher_type_object.voucher_type_name= ""
							voucher_type_object.voucher_type_id= ""
						}
						this.setState({
							is_ledger_recommended: true,
							// voucher_type: response.voucher_type,
							voucher_type_guid:response.voucher_type_guid,
							voucher_type_object:voucher_type_object,
							cr_ledger_id: response.ledgers.type == 1 ? { value: response.ledgers.guid, label: decodeHtml(response.ledgers.name) } : {},
							dr_ledger_id: response.ledgers.type == 2 ? { value: response.ledgers.guid, label: decodeHtml(response.ledgers.name) } : {},
							cr_ledg_id: response.ledgers.type == 1 ? response.ledgers.ledger_id : "",
							dr_ledg_id: response.ledgers.type == 2 ? response.ledgers.ledger_id : "",
						})
					}
				} else {
					toast.error(data.message);
				}
			});
	}

	returnCRValue(){
		return (<>
		<tr>
			<td style={{ width: "60px" }}>
				<input type="text" className="form-control text-center" name="type" disabled={true} value="CR" />
			</td>
			<td>
				<Form>
					<Select
						className="Select-Search "
						classNamePrefix={"Select SelectLedger " + (this.state.is_ledger_recommended && Object.keys(this.state.cr_ledger_id).length != 0 ? " input-bg-green " : "")}
						value={this.state.cr_ledger_id}
						isDisabled={this.props.bulk_transaction_list.length == 0}
						isClearable={false}
						isRtl={false}
						isSearchable={true}
						name="cr_ledger_id"
						options={this.state.ledger_options}
						styles={SelectSearch}
						onChange={(option) => this.handleLedgerChange(option, 'cr_ledger_id')}
					/>
				</Form>
			</td>
			<td>
				<CurrencyInput
					type="text"
					className="form-control text-right"
					name="amount"
					autoComplete="off"
					placeholder={"₹ 0"}
					prefix="&#8377; "
					allowNegativeValue={false}
					decimalsLimit={2}
					disabled={true}
				/>
				<small className="error_right">
					{(this.props.is_valid_ledger === false && this.state.type == 2 && !this.state.new) && this.ledgerAmtErrorMessage()}
				</small>
			</td>
			<td>
				<CurrencyInput
					type="text"
					className="form-control text-right"
					name="debit_amt"
					autoComplete="off"
					decimalsLimit={2}
					prefix="&#8377; "
					placeholder={"₹ 0"}
					value={this.state.debit_amt}
					allowNegativeValue={false}
					disabled={true}
					onValueChange={(value, name) => this.amountChange(value, name)}
				/>
			</td>
		</tr>

		{/* Error */}
		{this.state.errors.cr_ledger_id &&
			<tr>
				<td colspan="2">
					<small className="error_right">{this.state.errors.cr_ledger_id}</small>
				</td>
			</tr>
		}

		{
			this.state.cr_cost_categories.map((category, index1) => {
				return <CostCategory
					key={"cr_category_" + index1 + category.category_guid}
					ledger_index={this.props.ledger_index}
					cost_category_index={index1}
					cost_categories={this.state.cr_cost_categories}
					cost_centers={category.cost_centers}
					category_amount={category.category_amount}
					category_guid={category.category_guid}
					category_name={category.category_name}
					cost_categories_list={this.state.cost_categories_list}
					removeCostCategory={this.removeCostCategory}
					ledger_amount={this.state.total_credit_amount}
					bulk_ledger_type={"cr"}
					is_valid_ledger={this.state.is_valid_ledger}
					read_only={this.props.read_only}
					transactionAmount={this.props.transactionAmount}
					voucherNos={this.props.voucherNos}
					// voucher_type={this.props.voucher_type}
					voucher_type={this.state.voucher_type_object.parent_voucher_type_id}
					submit_click={this.props.submit_click}
				/>
			})
		}

		{(!!this.state.cr_cost_categories.length) &&
			<tr>
				<td colSpan={4} className="text-right addcat">
					<button className="add-cat-btn" onClick={() => this.onAddCategory("cr")} >Add Category</button>
				</td>
			</tr>
		}

		</>)
	}

	returnDRValue(){
		console.log('return DR Value ');
		return (<><tr>
			<td>
				<input type="text" className="form-control text-center" name="type" disabled={true} value="DR" />
			</td>
			<td>
				<Form>
					<Select
						className="Select-Search "
						classNamePrefix={"Select SelectLedger " + (this.state.is_ledger_recommended && Object.keys(this.state.dr_ledger_id).length != 0 ? " input-bg-green " : "")}
						value={this.state.dr_ledger_id}
						isDisabled={this.props.bulk_transaction_list.length == 0}
						isClearable={false}
						isRtl={false}
						isSearchable={true}
						name="dr_ledger_id"
						options={this.state.ledger_options}
						styles={SelectSearch}
						onChange={(option) => this.handleLedgerChange(option, 'dr_ledger_id')}
					/>
				</Form>
			</td>
			<td>
				<CurrencyInput
					type="text"
					className="form-control text-right"
					name="credit_amt"
					autoComplete="off"
					placeholder={'₹ 0'}
					prefix="&#8377; "
					value={this.state.credit_amt}
					allowNegativeValue={false}
					decimalsLimit={2}
					disabled={true}
					onValueChange={(value, name) => this.amountChange(value, name)}
				/>
				<small className="error_right">
					{(this.state.is_valid_ledger === false && this.state.type == 2 && !this.state.new) && this.ledgerAmtErrorMessage()}
				</small>
			</td>
			<td>
				<CurrencyInput
					type="text"
					className="form-control text-right"
					name="amount"
					autoComplete="off"
					decimalsLimit={2}
					prefix="&#8377; "
					placeholder={"₹ 0"}
					allowNegativeValue={false}
					disabled={true}
				/>
			</td>
		</tr>

		{/* Error */}
		{this.state.errors.dr_ledger_id &&
			<tr>
				<td colspan="2">
					<small className="error_right">{this.state.errors.dr_ledger_id}</small>
				</td>
			</tr>
		}
		{/* Error */}

		{
			this.state.dr_cost_categories.map((category, index1) => {
				return <CostCategory
					key={"dr_category_" + index1 + category.category_guid}
					ledger_index={this.props.ledger_index}
					cost_category_index={index1}
					cost_categories={this.state.dr_cost_categories}
					cost_centers={category.cost_centers}
					category_amount={category.category_amount}
					category_guid={category.category_guid}
					category_name={category.category_name}
					cost_categories_list={this.state.cost_categories_list}
					removeCostCategory={this.removeCostCategory}
					ledger_amount={this.state.total_debit_amount}
					bulk_ledger_type={"dr"}
					is_valid_ledger={this.state.is_valid_ledger}
					read_only={this.props.read_only}
					transactionAmount={this.props.transactionAmount}
					voucherNos={this.props.voucherNos}
					// voucher_type={this.props.voucher_type}
					voucher_type={this.state.voucher_type_object.parent_voucher_type_id}
					submit_click={this.props.submit_click}
				/>
			})
		}

		{(!!this.state.dr_cost_categories.length) &&
			<tr>
				<td colSpan={4} className="text-right addcat">
					<button className="add-cat-btn" onClick={() => this.onAddCategory("dr")} >Add Category</button>
				</td>
			</tr>
		}</>)
	}

	ledgerAsPerVoucherType(){
console.log(' voucher Type value ------  ' , this.state.voucher_type)
		//type 1 = CR
		//type 2 = DR
		if (this.state.voucher_type_object.parent_voucher_type_id == 2) {
		return(	
		<>{this.returnDRValue()}	
			{this.returnCRValue()}</>
			)
		}
		else if(this.state.voucher_type_object.parent_voucher_type_id == 3){
			console.log("voucher type valeee --------",this.state.voucher_type);
			return(	
				<>{this.returnCRValue()}
				{this.returnDRValue()}</>
				)
		}
		else if(this.state.voucher_type_object.parent_voucher_type_id == 4){
			return(	
				<>{this.returnDRValue()}
				{this.returnCRValue()}</>
				)
		}
		else if (this.state.voucher_type_object.parent_voucher_type_id == 1) {
			return(	
				<>{this.returnCRValue()}
				{this.returnDRValue()}</>
				
				)
		} else if (this.state.voucher_type_object.parent_voucher_type_id == 5) {
			return(	
				<>{this.returnDRValue()}
				{this.returnCRValue()}</>
				)
		} else {
			return(	
				<>{this.returnCRValue()}
				{this.returnDRValue()}</>
				)
		}
	}
	render() {
		console.log("voucher type",this.state.voucher_type_object)

		// Intro js user guid 
		const {
			stepsEnabled,
			steps,
			initialStep,
			hintsEnabled,
			hints
		} = this.state;
		// End Intro js user guid

		return (
			<main className="content-wrapper-inner-new">
				<RouteLeavingGuard
					when={this.state.is_dirty}
					saveChanges={this.saveBeforeLeave}
					closed={this.state.closed}
					closeAction={this.closeAction}
					resetForm={this.emptyStateValue}
				/>
				<div className="container-fluid container-padding-new">
					<div className="row">
				
						{/* Side Bar */}
						<div className="col-xl-3 col-lg-4 col-md-4 col-sm-5 sidebar-listing">
							<div className="sidebar-fixed">
								<div className="sidebar-new-header">
									<div className="breadcrumb-header">
										<BreadCrum level={2} />
									</div>
								</div>

								<div className="sidebar-new-sec">
									<div className="sidebar-tag-list">
										{/* Tags */}
										{/*<span class="sidebar-tagname">Since Beginning</span>*/}
										<AppliedFilterTag tagValue={this.getDateRange()} />
										<AppliedFilterTag tagValue={this.getWidgetFilter()} />
										<h4 className="side-trans-h">Transactions (Selected: {this.props.bulk_transaction_list.length + "/" + this.props.transaction_count.pending})</h4>
										{/* End Tags */}

										{/* Cards */}
										{
											this.props.bulk_transaction_list.map((transaction, index) => {
												return <TransactionCard
													transaction={transaction}
													key={transaction.id}
													removeTransaction={this.removeTransaction} />
											})
										}
										{/* Cards */}
									</div>
								</div>
							</div>
						</div>
						{/* End Side Bar */}



						{/* Right section */}
						<div className="col-xl-9 col-lg-8 col-md-8 col-sm-7 main-content-sec-new">
							{/* Intro js user guid  */}
							<Steps
								enabled={stepsEnabled}
								steps={steps}
								initialStep={initialStep}
								onExit={this.onExit}
							/>
							{/* End Intro js user guid  */}

							<div className="main-content-inner-new">
								{/* Sub Header Inner */}
								<div className="sub-header-inner-new">
									<div className="row">
										<div className="col-sm-12">
											<div className="sub-header-lr-new">
												<div className="shleft-new">
													<h3 className="heading-shi">Bulk Ledger Entry</h3>
													<div onClick={this.toggleSteps}>
														<OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Help</Tooltip>}>
															<span class="help-icon"><span class="icon-Information help-icon"></span></span>
														</OverlayTrigger>
													</div>
												</div>

												<div className="shright-new">
													<h3 className="heading-shi mb-0">
														<Link onClick={() => { this.props.history.goBack() }}>
															<span className="icon-Cross-with-grey-circle "><span className="path1"></span><span className="path2"></span><span className="path3"></span></span>
														</Link>
													</h3>
												</div>
											</div>
										</div>
									</div>
								</div>
								{/* End Sub Header Inner */}

								<div className="form-content-sec-new ledgerbf_moneyin">
									<div className="form-sec-inner-new">

										<div className="row">
											<div className="col-sm-12">
												<div className="form-main-title-new">
													<h4 className="form-title-heading-new">Ledger</h4>
												</div>
											</div>

											<div className="col-sm-12">
												{/* <p className="blue-text d-flex align-items-center flex-wrap mb-xxl-3 mb-xl-2"> */}
												{/* <p className="blue-text d-flex align-items-center flex-wrap mb-0"> */}
												<p className="ledger-icon-bar">
													{/* <span className="d-flex align-items-center mr-auto"> */}
													<span className="ledger-left-bar">
														{/* <OverlayTrigger overlay={<Tooltip>Refresh Ledgers</Tooltip>}>
															<button class="btn refresh-icon-new-round blink-green-btn mr-2" onClick={() => this.getLedgerNameList(true)}><span class="icon-refresh"></span></button>
														</OverlayTrigger> */}
														<OverlayTrigger overlay={<Tooltip>Refresh Ledgers</Tooltip>}>
															<span class="icon-refresh-iconn refresh-licon blink-green-btn" onClick={() => this.getLedgerNameList(true)}><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span>
														</OverlayTrigger>
														{this.state.last_updated_at === null ?
															<span>Please import ledger to use this section</span>
															:
															<>
																{this.getSyncStatus(this.state.sync_status)}
																<span>
																	Last Sync: {this.state.last_updated_at}
																</span>
															</>
														}
													</span>
													<span className='ledger-right-bar'>
														{/* <OverlayTrigger overlay={<Tooltip className="">Refresh Recommendation</Tooltip>}>
															<button onClick={this.getBulkLedgerRecommendation} class="btn refresh-icon-new-round"><span class="icon-invoice1 white-icon"></span></button>
														</OverlayTrigger> */}

														<OverlayTrigger overlay={<Tooltip className="">Refresh Recommendation</Tooltip>}>
															<span class="icon-Group-4507 refresh-recom-icon ledger-trs-icon mr-0" onClick={this.getBulkLedgerRecommendation}><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span></span>
														</OverlayTrigger>
													</span>
												</p>
											</div>
										</div>





										{/* <div className="d-xl-flex align-items-center gap-10  ">
											<p className="gray-text mb-0">Voucher Type:</p>
											<Form>
												<Form.Group controlId="exampleForm.ControlSelect1" className='mb-0'>
													<select
														className={"form-control"}
														style={{ minWidth: "170px" }}
														value={this.state.voucher_type}
														onChange={this.handleChange}
														disabled={this.props.bulk_transaction_list.length == 0}
														name="voucher_type">
														<option value="">Voucher Type</option>
														<option value={1}>Contra</option>
														<option value={4}>Journal</option>
														<option value={2}>Payment</option>
														<option value={6}>Purchase</option>
														<option value={3}>Receipt</option>
														<option value={5}>Sales</option>
													</select>
													{this.state.errors.voucher_type && <small className="text-muted s-reg error-msg">{this.state.errors.voucher_type}</small>}
												</Form.Group>
											</Form>

											{1 === 0 ?
												<p className="blue-text mb-0 ml-auto">Please import ledger to use this section</p>
												:
												<>
													<p className="blue-text mb-0 ml-auto d-flex align-items-center">
														{this.getSyncStatus(this.state.sync_status)}
														Last Sync: {this.state.last_updated_at}
													</p>
												</>
											}
										</div> */}

										<div className="ledger-entery-new">
											<div className="row">
												<div className="col-sm-12">
													<div className="d-flex flex-xl-row flex-lg-column align-items-xl-center gap-10 ">
														{/* <p className="gray-text mb-0">Voucher Type:</p> */}
														<div className="d-flex align-items-center gap-10 order-xl-0 order-1">
															<lable class="lable-input mb-0">Voucher Type:</lable>
															<Form>
																<Form.Group controlId="exampleForm.ControlSelect1" className='mb-0'>
																	<select
																		className={"form-control"}
																		style={{ minWidth: "170px" }}
																		value={this.state.voucher_type_guid}
																		onChange={this.handleChangeVoucherType}
																		disabled={this.props.bulk_transaction_list.length == 0}
																		name="voucher_type_guid">
																		<option value="">Voucher Type</option>

																		{/* <option value={1}>Contra</option>
																		<option value={4}>Journal</option>
																		<option value={2}>Payment</option>
																		<option value={6}>Purchase</option>
																		<option value={3}>Receipt</option>
																		<option value={5}>Sales</option> */}
																		 {
																			this.state.voucherTypeList.map((value,index)=>{
																				return (
																					<option key={index} value={value.guid}>
																				{value.name}
																					</option>
																				);
																			})
																		}
																	</select>
																	{this.state.errors.voucher_type && <small className="text-muted s-reg error-msg">{this.state.errors.voucher_type}</small>}
																</Form.Group>
															</Form>
															{(this.state.voucherTypeList.length == 0) && <small className="error_left">Please sync with tally to get Voucher Types</small>}
														</div>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-xl-12">
													{/* Table */}
													<div className="table-responsive main-invoice-item-new mt-3">
														<Table className="ledger-table">
															<thead>
																<tr>
																	<th>CR/DR</th>
																	<th className="w-50">Ledger</th>
																	<th className="text-right">Debit</th>
																	<th className="text-right">Credit</th>
																</tr>
															</thead>

															<tbody>
																{this.ledgerAsPerVoucherType()}
																
																

																<tr>
																	<td></td>
																	<td className="ledger-total">Total (₹)</td>
																	<td>
																		<CurrencyInput
																			type="text"
																			className="form-control text-right"
																			name="amount"
																			value={this.state.total_debit_amount}
																			autoComplete="off"
																			decimalsLimit={2}
																			prefix="&#8377; "
																			placeholder={"₹ 0"}
																			allowNegativeValue={false}
																			disabled={true}
																		/>
																	</td>
																	<td>
																		<CurrencyInput
																			type="text"
																			className="form-control text-right"
																			name="amount"
																			value={this.state.total_credit_amount}
																			autoComplete="off"
																			decimalsLimit={2}
																			prefix="&#8377; "
																			placeholder={"₹ 0"}
																			allowNegativeValue={false}
																			disabled={true}
																		/>
																	</td>
																</tr>

															</tbody>
														</Table>
													</div>

													{/* <div className={"clearfix bottomborder mb-2" + (this.state.is_accounting_narration == true ? "mb-2 " : " ")}>
														<p className="float-lg-left s-sbold ft-sz-20 blu-color mb-2">Accounting Narration</p>
													</div> */}
													<div className="row">
														<div className="col-sm-12">
															<div className="form-main-title-new pb-2 mb-2">
																<h5 className="form-title-heading-new size-xviii">Accounting Narration</h5>
															</div>
															<Form.Group controlId="exampleForm.ControlTextarea1" className="mb-2">
																<Form.Control as="textarea" rows={2} value={this.state.accounting_narration}
																	name="accounting_narration" onChange={this.handleChange} onBlur={this.handleAccountingNarration} />
																{this.state.errors.accounting_narration && <small className="form-text text-muted position-relative error-msg s-reg">{this.state.errors.accounting_narration}</small>}
															</Form.Group>
															<small className="error_right d-block">
																{this.props.bulk_transaction_list.length == 0 ? "Please select at least one transaction" : ""}
															</small>
														</div>
													</div>
												</div>
												{/* End Table */}
											</div>
										</div>
									</div>
								</div>

								<div className="row">
									<div className="col-sm-12 text-right mt-3">
										<Button variant="success apply_btn_new savebf_moneyin " onClick={this.saveVoucher} disabled={this.props.bulk_transaction_list.length == 0}>Create Entries</Button>
									</div>
								</div>
							</div>
						</div>
						{/* End right section */}
					</div>
				</div>
			</main>
		)
	}
}

function mapStateToProps(state) {
	const all_value = state.MoneyLink || {};
	const is_transaction_loading = all_value.is_transaction_loading;
	const filter_by = all_value.filter_by
	const session_value = state.Session || {};
	const features_permissions_list = session_value.features_permissions_list || [];
	const bulk_transaction_list = all_value.bulk_transaction_list || [];
	const ledger_recommendation_param = session_value.entity_config_param.ledger_recommendation || 0;
	const financial_year_name = all_value.financial_year_name;
	const filter_card = all_value.filter_card;

	return {
		is_transaction_loading, filter_by, features_permissions_list, all_value, bulk_transaction_list, ledger_recommendation_param, financial_year_name, filter_card
	};
}

export default connect(mapStateToProps)(withRouter(BulkLedgerForm));